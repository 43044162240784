.withdrawals-dashboard-history-table
	.ant-table
		table
			border-spacing: 0 1px

		.ant-table-thead
			display: none

		.ant-table-row
			position: relative
			border-radius: 0
			cursor: auto
			user-select: auto

			&::after
				content: ''
				position: absolute
				bottom: 0
				left: 0
				right: 0
				height: 1px
				background: $backgroundColor-gray-200

			&:hover
				box-shadow: none

		.ant-table-cell
			padding: rem(12) 0
			border-radius: 0

			&:first-child
				padding-right: rem(20)

			&:last-child
				padding-left: rem(20)

			&:hover
				box-shadow: none
