// @import ~antd/es/empty/style/index.css

.ant-empty
	$empty: &

	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	flex-wrap: nowrap
	margin: rem(32) rem(16) rem(16) rem(16)

	&-description
		font-size: rem(14)
		font-weight: 500
		color: $textColor-purple-dark

	&-img-default
		margin-bottom: rem(16)

		&-g
			fill: $fill-purple-base

		&-path-1
			fill: $fill-purple-medium
			stroke: darken($stroke-purple-medium, 10%)

		&-path-3
			fill: $fill-white
			stroke: darken($stroke-white, 10%)

		&-path-4
			fill: $fill-purple-medium
			stroke: darken($stroke-purple-medium, 10%)

		&-path-5
			fill: $fill-white
			stroke: darken($stroke-white, 10%)

		&-ellipse
			fill: darken($fill-white, 5%)
