.ant-drawer
	$drawer: &

	&.mobile-nav-drawer
		top: rem(88) // 88px = header height

		#{$drawer}-mask
			background: rgba($backgroundColor-purple-light, 50%)
			backdrop-filter: blur(4px)

		#{$drawer}-content
			background: $backgroundColor-white

		#{$drawer}-wrapper-body
			height: 100%
			padding: rem(16) 0

		#{$drawer}-body
			height: 100%
			display: flex
			flex-direction: column
			justify-content: space-between

		#{$drawer}-header, #{$drawer}-footer
			display: none
