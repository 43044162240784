/* Converted Tailwind Config to sass */
$columns-1: 1
$columns-2: 2
$columns-3: 3
$columns-4: 4
$columns-5: 5
$columns-6: 6
$columns-7: 7
$columns-8: 8
$columns-9: 9
$columns-10: 10
$columns-11: 11
$columns-12: 12
$columns-auto: auto

$animation-none: none
$animation-spin: spin 1s linear infinite

$aspectRatio-auto: auto
$aspectRatio-square: 1 / 1
$aspectRatio-portrait: 9 / 16
$aspectRatio-landscape: 16 / 9

$backdropBlur-0: 0
$backdropBlur-sm: 4px
$backdropBlur-md: 8px
$backdropBlur-lg: 12px

$backdropBrightness-0: 0
$backdropBrightness-5: 5%
$backdropBrightness-10: 10%
$backdropBrightness-15: 15%
$backdropBrightness-20: 20%
$backdropBrightness-25: 25%
$backdropBrightness-30: 30%
$backdropBrightness-35: 35%
$backdropBrightness-40: 40%
$backdropBrightness-45: 45%
$backdropBrightness-50: 50%
$backdropBrightness-55: 55%
$backdropBrightness-60: 60%
$backdropBrightness-65: 65%
$backdropBrightness-70: 70%
$backdropBrightness-75: 75%
$backdropBrightness-80: 80%
$backdropBrightness-85: 85%
$backdropBrightness-90: 90%
$backdropBrightness-95: 95%
$backdropBrightness-100: 100%

$backdropContrast-0: 0
$backdropContrast-5: 5%
$backdropContrast-10: 10%
$backdropContrast-15: 15%
$backdropContrast-20: 20%
$backdropContrast-25: 25%
$backdropContrast-30: 30%
$backdropContrast-35: 35%
$backdropContrast-40: 40%
$backdropContrast-45: 45%
$backdropContrast-50: 50%
$backdropContrast-55: 55%
$backdropContrast-60: 60%
$backdropContrast-65: 65%
$backdropContrast-70: 70%
$backdropContrast-75: 75%
$backdropContrast-80: 80%
$backdropContrast-85: 85%
$backdropContrast-90: 90%
$backdropContrast-95: 95%
$backdropContrast-100: 100%
$backdropContrast-125: 125%
$backdropContrast-150: 150%
$backdropContrast-200: 200%

$backdropGrayscale-0: 0
$backdropGrayscale-5: 5%
$backdropGrayscale-10: 10%
$backdropGrayscale-15: 15%
$backdropGrayscale-20: 20%
$backdropGrayscale-25: 25%
$backdropGrayscale-30: 30%
$backdropGrayscale-35: 35%
$backdropGrayscale-40: 40%
$backdropGrayscale-45: 45%
$backdropGrayscale-50: 50%
$backdropGrayscale-55: 55%
$backdropGrayscale-60: 60%
$backdropGrayscale-65: 65%
$backdropGrayscale-70: 70%
$backdropGrayscale-75: 75%
$backdropGrayscale-80: 80%
$backdropGrayscale-85: 85%
$backdropGrayscale-90: 90%
$backdropGrayscale-95: 95%
$backdropGrayscale-100: 100%

$backdropHueRotate-0: 0
$backdropHueRotate-15: 15deg
$backdropHueRotate-30: 30deg
$backdropHueRotate-45: 45deg
$backdropHueRotate-60: 60deg
$backdropHueRotate-75: 75deg
$backdropHueRotate-90: 90deg
$backdropHueRotate-180: 180deg
$backdropHueRotate-270: 270deg

$backdropInvert-0: 0
$backdropInvert-5: 5%
$backdropInvert-10: 10%
$backdropInvert-15: 15%
$backdropInvert-20: 20%
$backdropInvert-25: 25%
$backdropInvert-30: 30%
$backdropInvert-35: 35%
$backdropInvert-40: 40%
$backdropInvert-45: 45%
$backdropInvert-50: 50%
$backdropInvert-55: 55%
$backdropInvert-60: 60%
$backdropInvert-65: 65%
$backdropInvert-70: 70%
$backdropInvert-75: 75%
$backdropInvert-80: 80%
$backdropInvert-85: 85%
$backdropInvert-90: 90%
$backdropInvert-95: 95%
$backdropInvert-100: 100%

$backdropOpacity-0: 0
$backdropOpacity-5: 5%
$backdropOpacity-10: 10%
$backdropOpacity-15: 15%
$backdropOpacity-20: 20%
$backdropOpacity-25: 25%
$backdropOpacity-30: 30%
$backdropOpacity-35: 35%
$backdropOpacity-40: 40%
$backdropOpacity-45: 45%
$backdropOpacity-50: 50%
$backdropOpacity-55: 55%
$backdropOpacity-60: 60%
$backdropOpacity-65: 65%
$backdropOpacity-70: 70%
$backdropOpacity-75: 75%
$backdropOpacity-80: 80%
$backdropOpacity-85: 85%
$backdropOpacity-90: 90%
$backdropOpacity-95: 95%
$backdropOpacity-100: 100%

$backdropSaturate-0: 0
$backdropSaturate-5: 5%
$backdropSaturate-10: 10%
$backdropSaturate-15: 15%
$backdropSaturate-20: 20%
$backdropSaturate-25: 25%
$backdropSaturate-30: 30%
$backdropSaturate-35: 35%
$backdropSaturate-40: 40%
$backdropSaturate-45: 45%
$backdropSaturate-50: 50%
$backdropSaturate-55: 55%
$backdropSaturate-60: 60%
$backdropSaturate-65: 65%
$backdropSaturate-70: 70%
$backdropSaturate-75: 75%
$backdropSaturate-80: 80%
$backdropSaturate-85: 85%
$backdropSaturate-90: 90%
$backdropSaturate-95: 95%
$backdropSaturate-100: 100%
$backdropSaturate-125: 125%
$backdropSaturate-150: 150%
$backdropSaturate-200: 200%

$backdropSepia-0: 0
$backdropSepia-1: 1

$backgroundColor-inherit: inherit
$backgroundColor-current: currentColor
$backgroundColor-transparent: transparent
$backgroundColor-white: #FFFFFF
$backgroundColor-black: #000000
$backgroundColor-gray-100: #F3F3F3
$backgroundColor-gray-200: #DADADA
$backgroundColor-gray-500: #626066
$backgroundColor-gray-900: #111010
$backgroundColor-purple-base: #7569FF
$backgroundColor-purple-light: #EEECFE
$backgroundColor-purple-medium: #E3E0FD
$backgroundColor-purple-dark: #8B87B0
$backgroundColor-pink: #D869FF
$backgroundColor-cream: #FFFCEF
$backgroundColor-success: #00C11A
$backgroundColor-warning: #FBBF24
$backgroundColor-error: #EB001B

$backgroundImage-none: none
$backgroundImage-gradient-to-t: (linear-gradient(to top, var(--tw-gradient-stops)))
$backgroundImage-gradient-to-b: (linear-gradient(to bottom, var(--tw-gradient-stops)))
$backgroundImage-gradient-to-l: (linear-gradient(to left, var(--tw-gradient-stops)))
$backgroundImage-gradient-to-r: (linear-gradient(to right, var(--tw-gradient-stops)))
$backgroundImage-gradient-to-tl: (linear-gradient(to top left, var(--tw-gradient-stops)))
$backgroundImage-gradient-to-tr: (linear-gradient(to top right, var(--tw-gradient-stops)))
$backgroundImage-gradient-to-bl: (linear-gradient(to bottom left, var(--tw-gradient-stops)))
$backgroundImage-gradient-to-br: (linear-gradient(to bottom right, var(--tw-gradient-stops)))

$backgroundOpacity-0: 0
$backgroundOpacity-5: 5%
$backgroundOpacity-10: 10%
$backgroundOpacity-15: 15%
$backgroundOpacity-20: 20%
$backgroundOpacity-25: 25%
$backgroundOpacity-30: 30%
$backgroundOpacity-35: 35%
$backgroundOpacity-40: 40%
$backgroundOpacity-45: 45%
$backgroundOpacity-50: 50%
$backgroundOpacity-55: 55%
$backgroundOpacity-60: 60%
$backgroundOpacity-65: 65%
$backgroundOpacity-70: 70%
$backgroundOpacity-75: 75%
$backgroundOpacity-80: 80%
$backgroundOpacity-85: 85%
$backgroundOpacity-90: 90%
$backgroundOpacity-95: 95%
$backgroundOpacity-100: 100%

$backgroundPosition-center: center
$backgroundPosition-top: top
$backgroundPosition-bottom: bottom
$backgroundPosition-left: left
$backgroundPosition-right: right
$backgroundPosition-top-left: top left
$backgroundPosition-top-right: top right
$backgroundPosition-bottom-left: bottom left
$backgroundPosition-bottom-right: bottom right

$backgroundSize-auto: auto
$backgroundSize-cover: cover
$backgroundSize-contain: contain

$blur-0: 0
$blur-sm: 4px
$blur-md: 8px
$blur-lg: 12px

$brightness-0: 0
$brightness-5: 5%
$brightness-10: 10%
$brightness-15: 15%
$brightness-20: 20%
$brightness-25: 25%
$brightness-30: 30%
$brightness-35: 35%
$brightness-40: 40%
$brightness-45: 45%
$brightness-50: 50%
$brightness-55: 55%
$brightness-60: 60%
$brightness-65: 65%
$brightness-70: 70%
$brightness-75: 75%
$brightness-80: 80%
$brightness-85: 85%
$brightness-90: 90%
$brightness-95: 95%
$brightness-100: 100%

$borderColor-inherit: inherit
$borderColor-current: currentColor
$borderColor-transparent: transparent
$borderColor-white: #FFFFFF
$borderColor-black: #000000
$borderColor-gray-100: #F3F3F3
$borderColor-gray-200: #DADADA
$borderColor-gray-500: #626066
$borderColor-gray-900: #111010
$borderColor-purple-base: #7569FF
$borderColor-purple-light: #EEECFE
$borderColor-purple-medium: #E3E0FD
$borderColor-purple-dark: #8B87B0
$borderColor-pink: #D869FF
$borderColor-cream: #FFFCEF
$borderColor-success: #00C11A
$borderColor-warning: #FBBF24
$borderColor-error: #EB001B

$borderOpacity-0: 0
$borderOpacity-5: 5%
$borderOpacity-10: 10%
$borderOpacity-15: 15%
$borderOpacity-20: 20%
$borderOpacity-25: 25%
$borderOpacity-30: 30%
$borderOpacity-35: 35%
$borderOpacity-40: 40%
$borderOpacity-45: 45%
$borderOpacity-50: 50%
$borderOpacity-55: 55%
$borderOpacity-60: 60%
$borderOpacity-65: 65%
$borderOpacity-70: 70%
$borderOpacity-75: 75%
$borderOpacity-80: 80%
$borderOpacity-85: 85%
$borderOpacity-90: 90%
$borderOpacity-95: 95%
$borderOpacity-100: 100%

$borderRadius-0: 0
$borderRadius-xs: 0.5rem
$borderRadius-sm: 0.625rem
$borderRadius-md: 1rem
$borderRadius-lg: 1.75rem
$borderRadius-xl: 4.5rem
$borderRadius-full: 50%

$borderWidth-0: 0
$borderWidth-1px: 1px
$borderWidth-2px: 2px
$borderWidth-3px: 3px
$borderWidth-4px: 4px

$boxShadow-none: none
$boxShadow-inner: (inset 0 2px 8px 0 rgba(0, 0, 0, 0.1))
$boxShadow-sm: (0 2px 8px 0 rgba(0, 0, 0, 0.1))
$boxShadow-md: (0 4px 8px 0 rgba(0, 0, 0, 0.1))
$boxShadow-lg: (0 4px 12px 0 rgba(0, 0, 0, 0.1))

$boxShadowColor-inherit: inherit
$boxShadowColor-current: currentColor
$boxShadowColor-transparent: transparent
$boxShadowColor-white: #FFFFFF
$boxShadowColor-black: #000000
$boxShadowColor-gray-100: #F3F3F3
$boxShadowColor-gray-200: #DADADA
$boxShadowColor-gray-500: #626066
$boxShadowColor-gray-900: #111010
$boxShadowColor-purple-base: #7569FF
$boxShadowColor-purple-light: #EEECFE
$boxShadowColor-purple-medium: #E3E0FD
$boxShadowColor-purple-dark: #8B87B0
$boxShadowColor-pink: #D869FF
$boxShadowColor-cream: #FFFCEF
$boxShadowColor-success: #00C11A
$boxShadowColor-warning: #FBBF24
$boxShadowColor-error: #EB001B

$caretColor-inherit: inherit
$caretColor-current: currentColor
$caretColor-transparent: transparent
$caretColor-white: #FFFFFF
$caretColor-black: #000000
$caretColor-gray-100: #F3F3F3
$caretColor-gray-200: #DADADA
$caretColor-gray-500: #626066
$caretColor-gray-900: #111010
$caretColor-purple-base: #7569FF
$caretColor-purple-light: #EEECFE
$caretColor-purple-medium: #E3E0FD
$caretColor-purple-dark: #8B87B0
$caretColor-pink: #D869FF
$caretColor-cream: #FFFCEF
$caretColor-success: #00C11A
$caretColor-warning: #FBBF24
$caretColor-error: #EB001B

$accentColor-auto: auto
$accentColor-inherit: inherit
$accentColor-current: currentColor
$accentColor-transparent: transparent
$accentColor-white: #FFFFFF
$accentColor-black: #000000
$accentColor-gray-100: #F3F3F3
$accentColor-gray-200: #DADADA
$accentColor-gray-500: #626066
$accentColor-gray-900: #111010
$accentColor-purple-base: #7569FF
$accentColor-purple-light: #EEECFE
$accentColor-purple-medium: #E3E0FD
$accentColor-purple-dark: #8B87B0
$accentColor-pink: #D869FF
$accentColor-cream: #FFFCEF
$accentColor-success: #00C11A
$accentColor-warning: #FBBF24
$accentColor-error: #EB001B

$contrast-0: 0
$contrast-5: 5%
$contrast-10: 10%
$contrast-15: 15%
$contrast-20: 20%
$contrast-25: 25%
$contrast-30: 30%
$contrast-35: 35%
$contrast-40: 40%
$contrast-45: 45%
$contrast-50: 50%
$contrast-55: 55%
$contrast-60: 60%
$contrast-65: 65%
$contrast-70: 70%
$contrast-75: 75%
$contrast-80: 80%
$contrast-85: 85%
$contrast-90: 90%
$contrast-95: 95%
$contrast-100: 100%
$contrast-125: 125%
$contrast-150: 150%
$contrast-200: 200%


$content-none: none

$cursor-auto: auto
$cursor-default: default
$cursor-none: none
$cursor-not-allowed: not-allowed
$cursor-pointer: pointer

$divideColor-inherit: inherit
$divideColor-current: currentColor
$divideColor-transparent: transparent
$divideColor-white: #FFFFFF
$divideColor-black: #000000
$divideColor-gray-100: #F3F3F3
$divideColor-gray-200: #DADADA
$divideColor-gray-500: #626066
$divideColor-gray-900: #111010
$divideColor-purple-base: #7569FF
$divideColor-purple-light: #EEECFE
$divideColor-purple-medium: #E3E0FD
$divideColor-purple-dark: #8B87B0
$divideColor-pink: #D869FF
$divideColor-cream: #FFFCEF
$divideColor-success: #00C11A
$divideColor-warning: #FBBF24
$divideColor-error: #EB001B

$divideOpacity-0: 0
$divideOpacity-5: 5%
$divideOpacity-10: 10%
$divideOpacity-15: 15%
$divideOpacity-20: 20%
$divideOpacity-25: 25%
$divideOpacity-30: 30%
$divideOpacity-35: 35%
$divideOpacity-40: 40%
$divideOpacity-45: 45%
$divideOpacity-50: 50%
$divideOpacity-55: 55%
$divideOpacity-60: 60%
$divideOpacity-65: 65%
$divideOpacity-70: 70%
$divideOpacity-75: 75%
$divideOpacity-80: 80%
$divideOpacity-85: 85%
$divideOpacity-90: 90%
$divideOpacity-95: 95%
$divideOpacity-100: 100%

$divideWidth-0: 0
$divideWidth-1px: 1px
$divideWidth-2px: 2px
$divideWidth-3px: 3px
$divideWidth-4px: 4px

$dropShadow-none: 0 0 #0000
$dropShadow-sm: (0 2px 4px rgba(0, 0, 0, 0.1))
$dropShadow-md: (0 2px 4px rgba(0, 0, 0, 0.1),0 6px 8px rgba(0, 0, 0, 0.1))
$dropShadow-lg: (0 6px 8px rgba(0, 0, 0, 0.1),0 10px 12px rgba(0, 0, 0, 0.1))

$fill-inherit: inherit
$fill-current: currentColor
$fill-transparent: transparent
$fill-white: #FFFFFF
$fill-black: #000000
$fill-gray-100: #F3F3F3
$fill-gray-200: #DADADA
$fill-gray-500: #626066
$fill-gray-900: #111010
$fill-purple-base: #7569FF
$fill-purple-light: #EEECFE
$fill-purple-medium: #E3E0FD
$fill-purple-dark: #8B87B0
$fill-pink: #D869FF
$fill-cream: #FFFCEF
$fill-success: #00C11A
$fill-warning: #FBBF24
$fill-error: #EB001B

$grayscale-0: 0
$grayscale-5: 5%
$grayscale-10: 10%
$grayscale-15: 15%
$grayscale-20: 20%
$grayscale-25: 25%
$grayscale-30: 30%
$grayscale-35: 35%
$grayscale-40: 40%
$grayscale-45: 45%
$grayscale-50: 50%
$grayscale-55: 55%
$grayscale-60: 60%
$grayscale-65: 65%
$grayscale-70: 70%
$grayscale-75: 75%
$grayscale-80: 80%
$grayscale-85: 85%
$grayscale-90: 90%
$grayscale-95: 95%
$grayscale-100: 100%

$hueRotate-0: 0
$hueRotate-15: 15deg
$hueRotate-30: 30deg
$hueRotate-45: 45deg
$hueRotate-60: 60deg
$hueRotate-75: 75deg
$hueRotate-90: 90deg
$hueRotate-180: 180deg
$hueRotate-270: 270deg

$invert-0: 0
$invert-5: 5%
$invert-10: 10%
$invert-15: 15%
$invert-20: 20%
$invert-25: 25%
$invert-30: 30%
$invert-35: 35%
$invert-40: 40%
$invert-45: 45%
$invert-50: 50%
$invert-55: 55%
$invert-60: 60%
$invert-65: 65%
$invert-70: 70%
$invert-75: 75%
$invert-80: 80%
$invert-85: 85%
$invert-90: 90%
$invert-95: 95%
$invert-100: 100%

$flex-1: 1 1 0%
$flex-none: none
$flex-auto: 1 1 auto

$flexBasis-0: 0
$flexBasis-1: 0.25rem
$flexBasis-2: 0.5rem
$flexBasis-3: 0.75rem
$flexBasis-4: 1rem
$flexBasis-5: 1.25rem
$flexBasis-6: 1.5rem
$flexBasis-7: 1.75rem
$flexBasis-8: 2rem
$flexBasis-9: 2.25rem
$flexBasis-10: 2.5rem
$flexBasis-11: 2.75rem
$flexBasis-12: 3rem
$flexBasis-13: 3.25rem
$flexBasis-14: 3.5rem
$flexBasis-15: 3.75rem
$flexBasis-16: 4rem
$flexBasis-17: 4.25rem
$flexBasis-18: 4.5rem
$flexBasis-19: 4.75rem
$flexBasis-20: 5rem
$flexBasis-24: 6rem
$flexBasis-28: 7rem
$flexBasis-32: 8rem
$flexBasis-36: 9rem
$flexBasis-40: 10rem
$flexBasis-44: 11rem
$flexBasis-48: 12rem
$flexBasis-52: 13rem
$flexBasis-56: 14rem
$flexBasis-60: 15rem
$flexBasis-64: 16rem
$flexBasis-72: 18rem
$flexBasis-76: 19rem
$flexBasis-80: 20rem
$flexBasis-auto: auto
$flexBasis-0\.5: 0.125rem
$flexBasis-1\.5: 0.375rem
$flexBasis-2\.5: 0.625rem
$flexBasis-3\.5: 0.875rem
$flexBasis-4\.5: 1.125rem
$flexBasis-5\.5: 1.375rem
$flexBasis-6\.5: 1.625rem
$flexBasis-7\.5: 1.875rem
$flexBasis-5: 5%
$flexBasis-10: 10%
$flexBasis-15: 15%
$flexBasis-20: 20%
$flexBasis-25: 25%
$flexBasis-30: 30%
$flexBasis-35: 35%
$flexBasis-40: 40%
$flexBasis-45: 45%
$flexBasis-50: 50%
$flexBasis-55: 55%
$flexBasis-60: 60%
$flexBasis-65: 65%
$flexBasis-70: 70%
$flexBasis-75: 75%
$flexBasis-80: 80%
$flexBasis-85: 85%
$flexBasis-90: 90%
$flexBasis-95: 95%
$flexBasis-100: 100%

$flexGrow-0: 0
$flexGrow-1: 1

$flexShrink-0: 0
$flexShrink-1: 1

$fontFamily-base: (Poppins,Roboto,Inter,Arial)

$fontSize-8: 0.5rem
$fontSize-9: 0.5625rem
$fontSize-10: 0.625rem
$fontSize-11: 0.6875rem
$fontSize-12: 0.75rem
$fontSize-13: 0.8125rem
$fontSize-14: 0.875rem
$fontSize-15: 0.9375rem
$fontSize-16: 1rem
$fontSize-17: 1.0625rem
$fontSize-18: 1.125rem
$fontSize-19: 1.1875rem
$fontSize-20: 1.25rem
$fontSize-21: 1.3125rem
$fontSize-22: 1.375rem
$fontSize-23: 1.4375rem
$fontSize-24: 1.5rem
$fontSize-26: 1.625rem
$fontSize-28: 1.75rem
$fontSize-30: 1.875rem
$fontSize-32: 2rem
$fontSize-34: 2.125rem
$fontSize-36: 2.25rem
$fontSize-38: 2.375rem
$fontSize-40: 2.5rem
$fontSize-42: 2.625rem
$fontSize-44: 2.75rem
$fontSize-46: 2.875rem
$fontSize-48: 3rem
$fontSize-50: 3.125rem
$fontSize-52: 3.25rem
$fontSize-54: 3.375rem
$fontSize-56: 3.5rem
$fontSize-58: 3.625rem
$fontSize-60: 3.75rem
$fontSize-62: 3.875rem
$fontSize-64: 4rem

$fontWeight-100: 100
$fontWeight-200: 200
$fontWeight-300: 300
$fontWeight-400: 400
$fontWeight-500: 500
$fontWeight-600: 600
$fontWeight-700: 700
$fontWeight-800: 800
$fontWeight-900: 900

$lineHeight-none: 1
$lineHeight-sm: 1.25
$lineHeight-md: 1.5
$lineHeight-lg: 1.75

$gap-0: 0
$gap-1: 0.25rem
$gap-2: 0.5rem
$gap-3: 0.75rem
$gap-4: 1rem
$gap-5: 1.25rem
$gap-6: 1.5rem
$gap-7: 1.75rem
$gap-8: 2rem
$gap-9: 2.25rem
$gap-10: 2.5rem
$gap-11: 2.75rem
$gap-12: 3rem
$gap-13: 3.25rem
$gap-14: 3.5rem
$gap-15: 3.75rem
$gap-16: 4rem
$gap-17: 4.25rem
$gap-18: 4.5rem
$gap-19: 4.75rem
$gap-20: 5rem
$gap-24: 6rem
$gap-28: 7rem
$gap-32: 8rem
$gap-36: 9rem
$gap-40: 10rem
$gap-44: 11rem
$gap-48: 12rem
$gap-52: 13rem
$gap-56: 14rem
$gap-60: 15rem
$gap-64: 16rem
$gap-72: 18rem
$gap-76: 19rem
$gap-80: 20rem
$gap-1px: 1px
$gap-2px: 2px
$gap-3px: 3px
$gap-4px: 4px
$gap-0\.5: 0.125rem
$gap-1\.5: 0.375rem
$gap-2\.5: 0.625rem
$gap-3\.5: 0.875rem
$gap-4\.5: 1.125rem
$gap-5\.5: 1.375rem
$gap-6\.5: 1.625rem
$gap-7\.5: 1.875rem

$gradientColorStops-inherit: inherit
$gradientColorStops-current: currentColor
$gradientColorStops-transparent: transparent
$gradientColorStops-white: #FFFFFF
$gradientColorStops-black: #000000
$gradientColorStops-gray-100: #F3F3F3
$gradientColorStops-gray-200: #DADADA
$gradientColorStops-gray-500: #626066
$gradientColorStops-gray-900: #111010
$gradientColorStops-purple-base: #7569FF
$gradientColorStops-purple-light: #EEECFE
$gradientColorStops-purple-medium: #E3E0FD
$gradientColorStops-purple-dark: #8B87B0
$gradientColorStops-pink: #D869FF
$gradientColorStops-cream: #FFFCEF
$gradientColorStops-success: #00C11A
$gradientColorStops-warning: #FBBF24
$gradientColorStops-error: #EB001B

$gridAutoColumns-auto: auto
$gridAutoColumns-min: min-content
$gridAutoColumns-max: max-content
$gridAutoColumns-fr: (minmax(0, 1fr))

$gridAutoRows-auto: auto
$gridAutoRows-min: min-content
$gridAutoRows-max: max-content
$gridAutoRows-fr: (minmax(0, 1fr))

$gridColumn-1: span 1 / span 1
$gridColumn-2: span 2 / span 2
$gridColumn-3: span 3 / span 3
$gridColumn-4: span 4 / span 4
$gridColumn-5: span 5 / span 5
$gridColumn-6: span 6 / span 6
$gridColumn-7: span 7 / span 7
$gridColumn-8: span 8 / span 8
$gridColumn-9: span 9 / span 9
$gridColumn-10: span 10 / span 10
$gridColumn-11: span 11 / span 11
$gridColumn-12: span 12 / span 12
$gridColumn-auto: auto
$gridColumn-full: 1 / -1

$gridColumnEnd-1: 1
$gridColumnEnd-2: 2
$gridColumnEnd-3: 3
$gridColumnEnd-4: 4
$gridColumnEnd-5: 5
$gridColumnEnd-6: 6
$gridColumnEnd-7: 7
$gridColumnEnd-8: 8
$gridColumnEnd-9: 9
$gridColumnEnd-10: 10
$gridColumnEnd-11: 11
$gridColumnEnd-12: 12
$gridColumnEnd-13: 13
$gridColumnEnd-auto: auto

$gridColumnStart-1: 1
$gridColumnStart-2: 2
$gridColumnStart-3: 3
$gridColumnStart-4: 4
$gridColumnStart-5: 5
$gridColumnStart-6: 6
$gridColumnStart-7: 7
$gridColumnStart-8: 8
$gridColumnStart-9: 9
$gridColumnStart-10: 10
$gridColumnStart-11: 11
$gridColumnStart-12: 12
$gridColumnStart-13: 13
$gridColumnStart-auto: auto

$gridRow-1: span 1 / span 1
$gridRow-2: span 2 / span 2
$gridRow-3: span 3 / span 3
$gridRow-4: span 4 / span 4
$gridRow-5: span 5 / span 5
$gridRow-6: span 6 / span 6
$gridRow-auto: auto
$gridRow-full: 1 / -1

$gridRowStart-1: 1
$gridRowStart-2: 2
$gridRowStart-3: 3
$gridRowStart-4: 4
$gridRowStart-5: 5
$gridRowStart-6: 6
$gridRowStart-7: 7
$gridRowStart-auto: auto

$gridRowEnd-1: 1
$gridRowEnd-2: 2
$gridRowEnd-3: 3
$gridRowEnd-4: 4
$gridRowEnd-5: 5
$gridRowEnd-6: 6
$gridRowEnd-7: 7
$gridRowEnd-auto: auto

$gridTemplateColumns-1: (repeat(1, minmax(0, 1fr)))
$gridTemplateColumns-2: (repeat(2, minmax(0, 1fr)))
$gridTemplateColumns-3: (repeat(3, minmax(0, 1fr)))
$gridTemplateColumns-4: (repeat(4, minmax(0, 1fr)))
$gridTemplateColumns-5: (repeat(5, minmax(0, 1fr)))
$gridTemplateColumns-6: (repeat(6, minmax(0, 1fr)))
$gridTemplateColumns-7: (repeat(7, minmax(0, 1fr)))
$gridTemplateColumns-8: (repeat(8, minmax(0, 1fr)))
$gridTemplateColumns-9: (repeat(9, minmax(0, 1fr)))
$gridTemplateColumns-10: (repeat(10, minmax(0, 1fr)))
$gridTemplateColumns-11: (repeat(11, minmax(0, 1fr)))
$gridTemplateColumns-12: (repeat(12, minmax(0, 1fr)))
$gridTemplateColumns-none: none

$gridTemplateRows-1: (repeat(1, minmax(0, 1fr)))
$gridTemplateRows-2: (repeat(2, minmax(0, 1fr)))
$gridTemplateRows-3: (repeat(3, minmax(0, 1fr)))
$gridTemplateRows-4: (repeat(4, minmax(0, 1fr)))
$gridTemplateRows-5: (repeat(5, minmax(0, 1fr)))
$gridTemplateRows-6: (repeat(6, minmax(0, 1fr)))
$gridTemplateRows-none: none

$height-0: 0
$height-1: 0.25rem
$height-2: 0.5rem
$height-3: 0.75rem
$height-4: 1rem
$height-5: 1.25rem
$height-6: 1.5rem
$height-7: 1.75rem
$height-8: 2rem
$height-9: 2.25rem
$height-10: 2.5rem
$height-11: 2.75rem
$height-12: 3rem
$height-13: 3.25rem
$height-14: 3.5rem
$height-15: 3.75rem
$height-16: 4rem
$height-17: 4.25rem
$height-18: 4.5rem
$height-19: 4.75rem
$height-20: 5rem
$height-24: 6rem
$height-28: 7rem
$height-32: 8rem
$height-36: 9rem
$height-40: 10rem
$height-44: 11rem
$height-48: 12rem
$height-52: 13rem
$height-56: 14rem
$height-60: 15rem
$height-64: 16rem
$height-72: 18rem
$height-76: 19rem
$height-80: 20rem
$height-auto: auto
$height-min: min-content
$height-max: max-content
$height-fit: fit-content
$height-1px: 1px
$height-2px: 2px
$height-3px: 3px
$height-4px: 4px
$height-0\.5: 0.125rem
$height-1\.5: 0.375rem
$height-2\.5: 0.625rem
$height-3\.5: 0.875rem
$height-4\.5: 1.125rem
$height-5\.5: 1.375rem
$height-6\.5: 1.625rem
$height-7\.5: 1.875rem
$height-5: 5%
$height-10: 10%
$height-15: 15%
$height-20: 20%
$height-25: 25%
$height-30: 30%
$height-35: 35%
$height-40: 40%
$height-45: 45%
$height-50: 50%
$height-55: 55%
$height-60: 60%
$height-65: 65%
$height-70: 70%
$height-75: 75%
$height-80: 80%
$height-85: 85%
$height-90: 90%
$height-95: 95%
$height-100: 100%
$height-25vw: 25vw
$height-25vh: 25vh
$height-50vw: 50vw
$height-50vh: 50vh
$height-75vw: 75vw
$height-75vh: 75vh
$height-100vw: 100vw
$height-100vh: 100vh

$inset-0: 0
$inset-1: 0.25rem
$inset-2: 0.5rem
$inset-3: 0.75rem
$inset-4: 1rem
$inset-5: 1.25rem
$inset-6: 1.5rem
$inset-7: 1.75rem
$inset-8: 2rem
$inset-9: 2.25rem
$inset-10: 2.5rem
$inset-11: 2.75rem
$inset-12: 3rem
$inset-13: 3.25rem
$inset-14: 3.5rem
$inset-15: 3.75rem
$inset-16: 4rem
$inset-17: 4.25rem
$inset-18: 4.5rem
$inset-19: 4.75rem
$inset-20: 5rem
$inset-24: 6rem
$inset-28: 7rem
$inset-32: 8rem
$inset-36: 9rem
$inset-40: 10rem
$inset-44: 11rem
$inset-48: 12rem
$inset-52: 13rem
$inset-56: 14rem
$inset-60: 15rem
$inset-64: 16rem
$inset-72: 18rem
$inset-76: 19rem
$inset-80: 20rem
$inset-1px: 1px
$inset-2px: 2px
$inset-3px: 3px
$inset-4px: 4px
$inset-0\.5: 0.125rem
$inset-1\.5: 0.375rem
$inset-2\.5: 0.625rem
$inset-3\.5: 0.875rem
$inset-4\.5: 1.125rem
$inset-5\.5: 1.375rem
$inset-6\.5: 1.625rem
$inset-7\.5: 1.875rem
$inset-5: 5%
$inset-10: 10%
$inset-15: 15%
$inset-20: 20%
$inset-25: 25%
$inset-30: 30%
$inset-35: 35%
$inset-40: 40%
$inset-45: 45%
$inset-50: 50%
$inset-55: 55%
$inset-60: 60%
$inset-65: 65%
$inset-70: 70%
$inset-75: 75%
$inset-80: 80%
$inset-85: 85%
$inset-90: 90%
$inset-95: 95%
$inset-100: 100%

$letterSpacing-tighter: -0.05em
$letterSpacing-tight: -0.025em
$letterSpacing-normal: 0
$letterSpacing-wide: 0.025em
$letterSpacing-wider: 0.05em

$listStyleType-none: none
$listStyleType-decimal: decimal
$listStyleType-disc: disc

$margin-0: 0
$margin-1: 0.25rem
$margin-2: 0.5rem
$margin-3: 0.75rem
$margin-4: 1rem
$margin-5: 1.25rem
$margin-6: 1.5rem
$margin-7: 1.75rem
$margin-8: 2rem
$margin-9: 2.25rem
$margin-10: 2.5rem
$margin-11: 2.75rem
$margin-12: 3rem
$margin-13: 3.25rem
$margin-14: 3.5rem
$margin-15: 3.75rem
$margin-16: 4rem
$margin-17: 4.25rem
$margin-18: 4.5rem
$margin-19: 4.75rem
$margin-20: 5rem
$margin-24: 6rem
$margin-28: 7rem
$margin-32: 8rem
$margin-36: 9rem
$margin-40: 10rem
$margin-44: 11rem
$margin-48: 12rem
$margin-52: 13rem
$margin-56: 14rem
$margin-60: 15rem
$margin-64: 16rem
$margin-72: 18rem
$margin-76: 19rem
$margin-80: 20rem
$margin-auto: auto
$margin-1px: 1px
$margin-2px: 2px
$margin-3px: 3px
$margin-4px: 4px
$margin-0\.5: 0.125rem
$margin-1\.5: 0.375rem
$margin-2\.5: 0.625rem
$margin-3\.5: 0.875rem
$margin-4\.5: 1.125rem
$margin-5\.5: 1.375rem
$margin-6\.5: 1.625rem
$margin-7\.5: 1.875rem
$margin-5: 5%
$margin-10: 10%
$margin-15: 15%
$margin-20: 20%
$margin-25: 25%
$margin-30: 30%
$margin-35: 35%
$margin-40: 40%
$margin-45: 45%
$margin-50: 50%
$margin-55: 55%
$margin-60: 60%
$margin-65: 65%
$margin-70: 70%
$margin-75: 75%
$margin-80: 80%
$margin-85: 85%
$margin-90: 90%
$margin-95: 95%
$margin-100: 100%

$minWidth-0: 0
$minWidth-1: 0.25rem
$minWidth-2: 0.5rem
$minWidth-3: 0.75rem
$minWidth-4: 1rem
$minWidth-5: 1.25rem
$minWidth-6: 1.5rem
$minWidth-7: 1.75rem
$minWidth-8: 2rem
$minWidth-9: 2.25rem
$minWidth-10: 2.5rem
$minWidth-11: 2.75rem
$minWidth-12: 3rem
$minWidth-13: 3.25rem
$minWidth-14: 3.5rem
$minWidth-15: 3.75rem
$minWidth-16: 4rem
$minWidth-17: 4.25rem
$minWidth-18: 4.5rem
$minWidth-19: 4.75rem
$minWidth-20: 5rem
$minWidth-24: 6rem
$minWidth-28: 7rem
$minWidth-32: 8rem
$minWidth-36: 9rem
$minWidth-40: 10rem
$minWidth-44: 11rem
$minWidth-48: 12rem
$minWidth-52: 13rem
$minWidth-56: 14rem
$minWidth-60: 15rem
$minWidth-64: 16rem
$minWidth-72: 18rem
$minWidth-76: 19rem
$minWidth-80: 20rem
$minWidth-auto: auto
$minWidth-min: min-content
$minWidth-max: max-content
$minWidth-fit: fit-content
$minWidth-1px: 1px
$minWidth-2px: 2px
$minWidth-3px: 3px
$minWidth-4px: 4px
$minWidth-0\.5: 0.125rem
$minWidth-1\.5: 0.375rem
$minWidth-2\.5: 0.625rem
$minWidth-3\.5: 0.875rem
$minWidth-4\.5: 1.125rem
$minWidth-5\.5: 1.375rem
$minWidth-6\.5: 1.625rem
$minWidth-7\.5: 1.875rem
$minWidth-5: 5%
$minWidth-10: 10%
$minWidth-15: 15%
$minWidth-20: 20%
$minWidth-25: 25%
$minWidth-30: 30%
$minWidth-35: 35%
$minWidth-40: 40%
$minWidth-45: 45%
$minWidth-50: 50%
$minWidth-55: 55%
$minWidth-60: 60%
$minWidth-65: 65%
$minWidth-70: 70%
$minWidth-75: 75%
$minWidth-80: 80%
$minWidth-85: 85%
$minWidth-90: 90%
$minWidth-95: 95%
$minWidth-100: 100%
$minWidth-25vw: 25vw
$minWidth-25vh: 25vh
$minWidth-50vw: 50vw
$minWidth-50vh: 50vh
$minWidth-75vw: 75vw
$minWidth-75vh: 75vh
$minWidth-100vw: 100vw
$minWidth-100vh: 100vh

$maxWidth-0: 0
$maxWidth-1: 0.25rem
$maxWidth-2: 0.5rem
$maxWidth-3: 0.75rem
$maxWidth-4: 1rem
$maxWidth-5: 1.25rem
$maxWidth-6: 1.5rem
$maxWidth-7: 1.75rem
$maxWidth-8: 2rem
$maxWidth-9: 2.25rem
$maxWidth-10: 2.5rem
$maxWidth-11: 2.75rem
$maxWidth-12: 3rem
$maxWidth-13: 3.25rem
$maxWidth-14: 3.5rem
$maxWidth-15: 3.75rem
$maxWidth-16: 4rem
$maxWidth-17: 4.25rem
$maxWidth-18: 4.5rem
$maxWidth-19: 4.75rem
$maxWidth-20: 5rem
$maxWidth-24: 6rem
$maxWidth-28: 7rem
$maxWidth-32: 8rem
$maxWidth-36: 9rem
$maxWidth-40: 10rem
$maxWidth-44: 11rem
$maxWidth-48: 12rem
$maxWidth-52: 13rem
$maxWidth-56: 14rem
$maxWidth-60: 15rem
$maxWidth-64: 16rem
$maxWidth-72: 18rem
$maxWidth-76: 19rem
$maxWidth-80: 20rem
$maxWidth-auto: auto
$maxWidth-min: min-content
$maxWidth-max: max-content
$maxWidth-fit: fit-content
$maxWidth-1px: 1px
$maxWidth-2px: 2px
$maxWidth-3px: 3px
$maxWidth-4px: 4px
$maxWidth-0\.5: 0.125rem
$maxWidth-1\.5: 0.375rem
$maxWidth-2\.5: 0.625rem
$maxWidth-3\.5: 0.875rem
$maxWidth-4\.5: 1.125rem
$maxWidth-5\.5: 1.375rem
$maxWidth-6\.5: 1.625rem
$maxWidth-7\.5: 1.875rem
$maxWidth-5: 5%
$maxWidth-10: 10%
$maxWidth-15: 15%
$maxWidth-20: 20%
$maxWidth-25: 25%
$maxWidth-30: 30%
$maxWidth-35: 35%
$maxWidth-40: 40%
$maxWidth-45: 45%
$maxWidth-50: 50%
$maxWidth-55: 55%
$maxWidth-60: 60%
$maxWidth-65: 65%
$maxWidth-70: 70%
$maxWidth-75: 75%
$maxWidth-80: 80%
$maxWidth-85: 85%
$maxWidth-90: 90%
$maxWidth-95: 95%
$maxWidth-100: 100%
$maxWidth-25vw: 25vw
$maxWidth-25vh: 25vh
$maxWidth-50vw: 50vw
$maxWidth-50vh: 50vh
$maxWidth-75vw: 75vw
$maxWidth-75vh: 75vh
$maxWidth-100vw: 100vw
$maxWidth-100vh: 100vh
$maxWidth-screen-sm: 576px
$maxWidth-screen-md: 768px
$maxWidth-screen-lg: 992px
$maxWidth-screen-xl: 1200px
$maxWidth-screen-xxl: 1600px

$minHeight-0: 0
$minHeight-1: 0.25rem
$minHeight-2: 0.5rem
$minHeight-3: 0.75rem
$minHeight-4: 1rem
$minHeight-5: 1.25rem
$minHeight-6: 1.5rem
$minHeight-7: 1.75rem
$minHeight-8: 2rem
$minHeight-9: 2.25rem
$minHeight-10: 2.5rem
$minHeight-11: 2.75rem
$minHeight-12: 3rem
$minHeight-13: 3.25rem
$minHeight-14: 3.5rem
$minHeight-15: 3.75rem
$minHeight-16: 4rem
$minHeight-17: 4.25rem
$minHeight-18: 4.5rem
$minHeight-19: 4.75rem
$minHeight-20: 5rem
$minHeight-24: 6rem
$minHeight-28: 7rem
$minHeight-32: 8rem
$minHeight-36: 9rem
$minHeight-40: 10rem
$minHeight-44: 11rem
$minHeight-48: 12rem
$minHeight-52: 13rem
$minHeight-56: 14rem
$minHeight-60: 15rem
$minHeight-64: 16rem
$minHeight-72: 18rem
$minHeight-76: 19rem
$minHeight-80: 20rem
$minHeight-auto: auto
$minHeight-min: min-content
$minHeight-max: max-content
$minHeight-fit: fit-content
$minHeight-1px: 1px
$minHeight-2px: 2px
$minHeight-3px: 3px
$minHeight-4px: 4px
$minHeight-0\.5: 0.125rem
$minHeight-1\.5: 0.375rem
$minHeight-2\.5: 0.625rem
$minHeight-3\.5: 0.875rem
$minHeight-4\.5: 1.125rem
$minHeight-5\.5: 1.375rem
$minHeight-6\.5: 1.625rem
$minHeight-7\.5: 1.875rem
$minHeight-5: 5%
$minHeight-10: 10%
$minHeight-15: 15%
$minHeight-20: 20%
$minHeight-25: 25%
$minHeight-30: 30%
$minHeight-35: 35%
$minHeight-40: 40%
$minHeight-45: 45%
$minHeight-50: 50%
$minHeight-55: 55%
$minHeight-60: 60%
$minHeight-65: 65%
$minHeight-70: 70%
$minHeight-75: 75%
$minHeight-80: 80%
$minHeight-85: 85%
$minHeight-90: 90%
$minHeight-95: 95%
$minHeight-100: 100%
$minHeight-25vw: 25vw
$minHeight-25vh: 25vh
$minHeight-50vw: 50vw
$minHeight-50vh: 50vh
$minHeight-75vw: 75vw
$minHeight-75vh: 75vh
$minHeight-100vw: 100vw
$minHeight-100vh: 100vh

$maxHeight-0: 0
$maxHeight-1: 0.25rem
$maxHeight-2: 0.5rem
$maxHeight-3: 0.75rem
$maxHeight-4: 1rem
$maxHeight-5: 1.25rem
$maxHeight-6: 1.5rem
$maxHeight-7: 1.75rem
$maxHeight-8: 2rem
$maxHeight-9: 2.25rem
$maxHeight-10: 2.5rem
$maxHeight-11: 2.75rem
$maxHeight-12: 3rem
$maxHeight-13: 3.25rem
$maxHeight-14: 3.5rem
$maxHeight-15: 3.75rem
$maxHeight-16: 4rem
$maxHeight-17: 4.25rem
$maxHeight-18: 4.5rem
$maxHeight-19: 4.75rem
$maxHeight-20: 5rem
$maxHeight-24: 6rem
$maxHeight-28: 7rem
$maxHeight-32: 8rem
$maxHeight-36: 9rem
$maxHeight-40: 10rem
$maxHeight-44: 11rem
$maxHeight-48: 12rem
$maxHeight-52: 13rem
$maxHeight-56: 14rem
$maxHeight-60: 15rem
$maxHeight-64: 16rem
$maxHeight-72: 18rem
$maxHeight-76: 19rem
$maxHeight-80: 20rem
$maxHeight-auto: auto
$maxHeight-min: min-content
$maxHeight-max: max-content
$maxHeight-fit: fit-content
$maxHeight-1px: 1px
$maxHeight-2px: 2px
$maxHeight-3px: 3px
$maxHeight-4px: 4px
$maxHeight-0\.5: 0.125rem
$maxHeight-1\.5: 0.375rem
$maxHeight-2\.5: 0.625rem
$maxHeight-3\.5: 0.875rem
$maxHeight-4\.5: 1.125rem
$maxHeight-5\.5: 1.375rem
$maxHeight-6\.5: 1.625rem
$maxHeight-7\.5: 1.875rem
$maxHeight-5: 5%
$maxHeight-10: 10%
$maxHeight-15: 15%
$maxHeight-20: 20%
$maxHeight-25: 25%
$maxHeight-30: 30%
$maxHeight-35: 35%
$maxHeight-40: 40%
$maxHeight-45: 45%
$maxHeight-50: 50%
$maxHeight-55: 55%
$maxHeight-60: 60%
$maxHeight-65: 65%
$maxHeight-70: 70%
$maxHeight-75: 75%
$maxHeight-80: 80%
$maxHeight-85: 85%
$maxHeight-90: 90%
$maxHeight-95: 95%
$maxHeight-100: 100%
$maxHeight-25vw: 25vw
$maxHeight-25vh: 25vh
$maxHeight-50vw: 50vw
$maxHeight-50vh: 50vh
$maxHeight-75vw: 75vw
$maxHeight-75vh: 75vh
$maxHeight-100vw: 100vw
$maxHeight-100vh: 100vh

$objectPosition-center: center
$objectPosition-top: top
$objectPosition-bottom: bottom
$objectPosition-left: left
$objectPosition-right: right
$objectPosition-top-left: top left
$objectPosition-top-right: top right
$objectPosition-bottom-left: bottom left
$objectPosition-bottom-right: bottom right

$opacity-0: 0
$opacity-5: 5%
$opacity-10: 10%
$opacity-15: 15%
$opacity-20: 20%
$opacity-25: 25%
$opacity-30: 30%
$opacity-35: 35%
$opacity-40: 40%
$opacity-45: 45%
$opacity-50: 50%
$opacity-55: 55%
$opacity-60: 60%
$opacity-65: 65%
$opacity-70: 70%
$opacity-75: 75%
$opacity-80: 80%
$opacity-85: 85%
$opacity-90: 90%
$opacity-95: 95%
$opacity-100: 100%

$order-0: 0
$order-1: 1
$order-2: 2
$order-3: 3
$order-4: 4
$order-5: 5
$order-6: 6
$order-7: 7
$order-8: 8
$order-9: 9
$order-10: 10
$order-11: 11
$order-12: 12
$order-first: -9999
$order-last: 9999

$padding-0: 0
$padding-1: 0.25rem
$padding-2: 0.5rem
$padding-3: 0.75rem
$padding-4: 1rem
$padding-5: 1.25rem
$padding-6: 1.5rem
$padding-7: 1.75rem
$padding-8: 2rem
$padding-9: 2.25rem
$padding-10: 2.5rem
$padding-11: 2.75rem
$padding-12: 3rem
$padding-13: 3.25rem
$padding-14: 3.5rem
$padding-15: 3.75rem
$padding-16: 4rem
$padding-17: 4.25rem
$padding-18: 4.5rem
$padding-19: 4.75rem
$padding-20: 5rem
$padding-24: 6rem
$padding-28: 7rem
$padding-32: 8rem
$padding-36: 9rem
$padding-40: 10rem
$padding-44: 11rem
$padding-48: 12rem
$padding-52: 13rem
$padding-56: 14rem
$padding-60: 15rem
$padding-64: 16rem
$padding-72: 18rem
$padding-76: 19rem
$padding-80: 20rem
$padding-1px: 1px
$padding-2px: 2px
$padding-3px: 3px
$padding-4px: 4px
$padding-0\.5: 0.125rem
$padding-1\.5: 0.375rem
$padding-2\.5: 0.625rem
$padding-3\.5: 0.875rem
$padding-4\.5: 1.125rem
$padding-5\.5: 1.375rem
$padding-6\.5: 1.625rem
$padding-7\.5: 1.875rem
$padding-5: 5%
$padding-10: 10%
$padding-15: 15%
$padding-20: 20%
$padding-25: 25%
$padding-30: 30%
$padding-35: 35%
$padding-40: 40%
$padding-45: 45%
$padding-50: 50%
$padding-55: 55%
$padding-60: 60%
$padding-65: 65%
$padding-70: 70%
$padding-75: 75%
$padding-80: 80%
$padding-85: 85%
$padding-90: 90%
$padding-95: 95%
$padding-100: 100%

$placeholderColor-inherit: inherit
$placeholderColor-current: currentColor
$placeholderColor-transparent: transparent
$placeholderColor-white: #FFFFFF
$placeholderColor-black: #000000
$placeholderColor-gray-100: #F3F3F3
$placeholderColor-gray-200: #DADADA
$placeholderColor-gray-500: #626066
$placeholderColor-gray-900: #111010
$placeholderColor-purple-base: #7569FF
$placeholderColor-purple-light: #EEECFE
$placeholderColor-purple-medium: #E3E0FD
$placeholderColor-purple-dark: #8B87B0
$placeholderColor-pink: #D869FF
$placeholderColor-cream: #FFFCEF
$placeholderColor-success: #00C11A
$placeholderColor-warning: #FBBF24
$placeholderColor-error: #EB001B

$placeholderOpacity-0: 0
$placeholderOpacity-5: 5%
$placeholderOpacity-10: 10%
$placeholderOpacity-15: 15%
$placeholderOpacity-20: 20%
$placeholderOpacity-25: 25%
$placeholderOpacity-30: 30%
$placeholderOpacity-35: 35%
$placeholderOpacity-40: 40%
$placeholderOpacity-45: 45%
$placeholderOpacity-50: 50%
$placeholderOpacity-55: 55%
$placeholderOpacity-60: 60%
$placeholderOpacity-65: 65%
$placeholderOpacity-70: 70%
$placeholderOpacity-75: 75%
$placeholderOpacity-80: 80%
$placeholderOpacity-85: 85%
$placeholderOpacity-90: 90%
$placeholderOpacity-95: 95%
$placeholderOpacity-100: 100%

$outlineColor-inherit: inherit
$outlineColor-current: currentColor
$outlineColor-transparent: transparent
$outlineColor-white: #FFFFFF
$outlineColor-black: #000000
$outlineColor-gray-100: #F3F3F3
$outlineColor-gray-200: #DADADA
$outlineColor-gray-500: #626066
$outlineColor-gray-900: #111010
$outlineColor-purple-base: #7569FF
$outlineColor-purple-light: #EEECFE
$outlineColor-purple-medium: #E3E0FD
$outlineColor-purple-dark: #8B87B0
$outlineColor-pink: #D869FF
$outlineColor-cream: #FFFCEF
$outlineColor-success: #00C11A
$outlineColor-warning: #FBBF24
$outlineColor-error: #EB001B

$outlineOffset-0: 0
$outlineOffset-1px: 1px
$outlineOffset-2px: 2px
$outlineOffset-3px: 3px
$outlineOffset-4px: 4px

$outlineWidth-0: 0
$outlineWidth-1px: 1px
$outlineWidth-2px: 2px
$outlineWidth-3px: 3px
$outlineWidth-4px: 4px

$ringColor-inherit: inherit
$ringColor-current: currentColor
$ringColor-transparent: transparent
$ringColor-white: #FFFFFF
$ringColor-black: #000000
$ringColor-gray-100: #F3F3F3
$ringColor-gray-200: #DADADA
$ringColor-gray-500: #626066
$ringColor-gray-900: #111010
$ringColor-purple-base: #7569FF
$ringColor-purple-light: #EEECFE
$ringColor-purple-medium: #E3E0FD
$ringColor-purple-dark: #8B87B0
$ringColor-pink: #D869FF
$ringColor-cream: #FFFCEF
$ringColor-success: #00C11A
$ringColor-warning: #FBBF24
$ringColor-error: #EB001B

$ringOffsetColor-inherit: inherit
$ringOffsetColor-current: currentColor
$ringOffsetColor-transparent: transparent
$ringOffsetColor-white: #FFFFFF
$ringOffsetColor-black: #000000
$ringOffsetColor-gray-100: #F3F3F3
$ringOffsetColor-gray-200: #DADADA
$ringOffsetColor-gray-500: #626066
$ringOffsetColor-gray-900: #111010
$ringOffsetColor-purple-base: #7569FF
$ringOffsetColor-purple-light: #EEECFE
$ringOffsetColor-purple-medium: #E3E0FD
$ringOffsetColor-purple-dark: #8B87B0
$ringOffsetColor-pink: #D869FF
$ringOffsetColor-cream: #FFFCEF
$ringOffsetColor-success: #00C11A
$ringOffsetColor-warning: #FBBF24
$ringOffsetColor-error: #EB001B

$ringOffsetWidth-0: 0
$ringOffsetWidth-1px: 1px
$ringOffsetWidth-2px: 2px
$ringOffsetWidth-3px: 3px
$ringOffsetWidth-4px: 4px

$ringOpacity-0: 0
$ringOpacity-5: 5%
$ringOpacity-10: 10%
$ringOpacity-15: 15%
$ringOpacity-20: 20%
$ringOpacity-25: 25%
$ringOpacity-30: 30%
$ringOpacity-35: 35%
$ringOpacity-40: 40%
$ringOpacity-45: 45%
$ringOpacity-50: 50%
$ringOpacity-55: 55%
$ringOpacity-60: 60%
$ringOpacity-65: 65%
$ringOpacity-70: 70%
$ringOpacity-75: 75%
$ringOpacity-80: 80%
$ringOpacity-85: 85%
$ringOpacity-90: 90%
$ringOpacity-95: 95%
$ringOpacity-100: 100%

$ringWidth-0: 0
$ringWidth-1px: 1px
$ringWidth-2px: 2px
$ringWidth-3px: 3px
$ringWidth-4px: 4px

$rotate-0: 0
$rotate-15: 15deg
$rotate-30: 30deg
$rotate-45: 45deg
$rotate-60: 60deg
$rotate-75: 75deg
$rotate-90: 90deg
$rotate-180: 180deg
$rotate-270: 270deg

$saturate-0: 0
$saturate-5: 5%
$saturate-10: 10%
$saturate-15: 15%
$saturate-20: 20%
$saturate-25: 25%
$saturate-30: 30%
$saturate-35: 35%
$saturate-40: 40%
$saturate-45: 45%
$saturate-50: 50%
$saturate-55: 55%
$saturate-60: 60%
$saturate-65: 65%
$saturate-70: 70%
$saturate-75: 75%
$saturate-80: 80%
$saturate-85: 85%
$saturate-90: 90%
$saturate-95: 95%
$saturate-100: 100%
$saturate-125: 125%
$saturate-150: 150%
$saturate-200: 200%

$scale-0: 0
$scale-5: 5%
$scale-10: 10%
$scale-15: 15%
$scale-20: 20%
$scale-25: 25%
$scale-30: 30%
$scale-35: 35%
$scale-40: 40%
$scale-45: 45%
$scale-50: 50%
$scale-55: 55%
$scale-60: 60%
$scale-65: 65%
$scale-70: 70%
$scale-75: 75%
$scale-80: 80%
$scale-85: 85%
$scale-90: 90%
$scale-95: 95%
$scale-100: 100%
$scale-125: 125%
$scale-150: 150%
$scale-200: 200%

$scrollMargin-0: 0
$scrollMargin-1: 0.25rem
$scrollMargin-2: 0.5rem
$scrollMargin-3: 0.75rem
$scrollMargin-4: 1rem
$scrollMargin-5: 1.25rem
$scrollMargin-6: 1.5rem
$scrollMargin-7: 1.75rem
$scrollMargin-8: 2rem
$scrollMargin-9: 2.25rem
$scrollMargin-10: 2.5rem
$scrollMargin-11: 2.75rem
$scrollMargin-12: 3rem
$scrollMargin-13: 3.25rem
$scrollMargin-14: 3.5rem
$scrollMargin-15: 3.75rem
$scrollMargin-16: 4rem
$scrollMargin-17: 4.25rem
$scrollMargin-18: 4.5rem
$scrollMargin-19: 4.75rem
$scrollMargin-20: 5rem
$scrollMargin-24: 6rem
$scrollMargin-28: 7rem
$scrollMargin-32: 8rem
$scrollMargin-36: 9rem
$scrollMargin-40: 10rem
$scrollMargin-44: 11rem
$scrollMargin-48: 12rem
$scrollMargin-52: 13rem
$scrollMargin-56: 14rem
$scrollMargin-60: 15rem
$scrollMargin-64: 16rem
$scrollMargin-72: 18rem
$scrollMargin-76: 19rem
$scrollMargin-80: 20rem
$scrollMargin-1px: 1px
$scrollMargin-2px: 2px
$scrollMargin-3px: 3px
$scrollMargin-4px: 4px
$scrollMargin-0\.5: 0.125rem
$scrollMargin-1\.5: 0.375rem
$scrollMargin-2\.5: 0.625rem
$scrollMargin-3\.5: 0.875rem
$scrollMargin-4\.5: 1.125rem
$scrollMargin-5\.5: 1.375rem
$scrollMargin-6\.5: 1.625rem
$scrollMargin-7\.5: 1.875rem
$scrollMargin-5: 5%
$scrollMargin-10: 10%
$scrollMargin-15: 15%
$scrollMargin-20: 20%
$scrollMargin-25: 25%
$scrollMargin-30: 30%
$scrollMargin-35: 35%
$scrollMargin-40: 40%
$scrollMargin-45: 45%
$scrollMargin-50: 50%
$scrollMargin-55: 55%
$scrollMargin-60: 60%
$scrollMargin-65: 65%
$scrollMargin-70: 70%
$scrollMargin-75: 75%
$scrollMargin-80: 80%
$scrollMargin-85: 85%
$scrollMargin-90: 90%
$scrollMargin-95: 95%
$scrollMargin-100: 100%

$scrollPadding-0: 0
$scrollPadding-1: 0.25rem
$scrollPadding-2: 0.5rem
$scrollPadding-3: 0.75rem
$scrollPadding-4: 1rem
$scrollPadding-5: 1.25rem
$scrollPadding-6: 1.5rem
$scrollPadding-7: 1.75rem
$scrollPadding-8: 2rem
$scrollPadding-9: 2.25rem
$scrollPadding-10: 2.5rem
$scrollPadding-11: 2.75rem
$scrollPadding-12: 3rem
$scrollPadding-13: 3.25rem
$scrollPadding-14: 3.5rem
$scrollPadding-15: 3.75rem
$scrollPadding-16: 4rem
$scrollPadding-17: 4.25rem
$scrollPadding-18: 4.5rem
$scrollPadding-19: 4.75rem
$scrollPadding-20: 5rem
$scrollPadding-24: 6rem
$scrollPadding-28: 7rem
$scrollPadding-32: 8rem
$scrollPadding-36: 9rem
$scrollPadding-40: 10rem
$scrollPadding-44: 11rem
$scrollPadding-48: 12rem
$scrollPadding-52: 13rem
$scrollPadding-56: 14rem
$scrollPadding-60: 15rem
$scrollPadding-64: 16rem
$scrollPadding-72: 18rem
$scrollPadding-76: 19rem
$scrollPadding-80: 20rem
$scrollPadding-1px: 1px
$scrollPadding-2px: 2px
$scrollPadding-3px: 3px
$scrollPadding-4px: 4px
$scrollPadding-0\.5: 0.125rem
$scrollPadding-1\.5: 0.375rem
$scrollPadding-2\.5: 0.625rem
$scrollPadding-3\.5: 0.875rem
$scrollPadding-4\.5: 1.125rem
$scrollPadding-5\.5: 1.375rem
$scrollPadding-6\.5: 1.625rem
$scrollPadding-7\.5: 1.875rem
$scrollPadding-5: 5%
$scrollPadding-10: 10%
$scrollPadding-15: 15%
$scrollPadding-20: 20%
$scrollPadding-25: 25%
$scrollPadding-30: 30%
$scrollPadding-35: 35%
$scrollPadding-40: 40%
$scrollPadding-45: 45%
$scrollPadding-50: 50%
$scrollPadding-55: 55%
$scrollPadding-60: 60%
$scrollPadding-65: 65%
$scrollPadding-70: 70%
$scrollPadding-75: 75%
$scrollPadding-80: 80%
$scrollPadding-85: 85%
$scrollPadding-90: 90%
$scrollPadding-95: 95%
$scrollPadding-100: 100%

$sepia-0: 0
$sepia-1: 1

$skew-0: 0
$skew-1: 1deg
$skew-2: 2deg
$skew-3: 3deg
$skew-6: 6deg
$skew-12: 12deg

$space-0: 0
$space-1: 0.25rem
$space-2: 0.5rem
$space-3: 0.75rem
$space-4: 1rem
$space-5: 1.25rem
$space-6: 1.5rem
$space-7: 1.75rem
$space-8: 2rem
$space-9: 2.25rem
$space-10: 2.5rem
$space-11: 2.75rem
$space-12: 3rem
$space-13: 3.25rem
$space-14: 3.5rem
$space-15: 3.75rem
$space-16: 4rem
$space-17: 4.25rem
$space-18: 4.5rem
$space-19: 4.75rem
$space-20: 5rem
$space-24: 6rem
$space-28: 7rem
$space-32: 8rem
$space-36: 9rem
$space-40: 10rem
$space-44: 11rem
$space-48: 12rem
$space-52: 13rem
$space-56: 14rem
$space-60: 15rem
$space-64: 16rem
$space-72: 18rem
$space-76: 19rem
$space-80: 20rem
$space-0\.5: 0.125rem
$space-1\.5: 0.375rem
$space-2\.5: 0.625rem
$space-3\.5: 0.875rem
$space-4\.5: 1.125rem
$space-5\.5: 1.375rem
$space-6\.5: 1.625rem
$space-7\.5: 1.875rem

$stroke-inherit: inherit
$stroke-current: currentColor
$stroke-transparent: transparent
$stroke-white: #FFFFFF
$stroke-black: #000000
$stroke-gray-100: #F3F3F3
$stroke-gray-200: #DADADA
$stroke-gray-500: #626066
$stroke-gray-900: #111010
$stroke-purple-base: #7569FF
$stroke-purple-light: #EEECFE
$stroke-purple-medium: #E3E0FD
$stroke-purple-dark: #8B87B0
$stroke-pink: #D869FF
$stroke-cream: #FFFCEF
$stroke-success: #00C11A
$stroke-warning: #FBBF24
$stroke-error: #EB001B

$strokeWidth-0: 0
$strokeWidth-1: 1
$strokeWidth-2: 2
$strokeWidth-3: 3
$strokeWidth-4: 4

$textColor-inherit: inherit
$textColor-current: currentColor
$textColor-transparent: transparent
$textColor-white: #FFFFFF
$textColor-black: #000000
$textColor-gray-100: #F3F3F3
$textColor-gray-200: #DADADA
$textColor-gray-500: #626066
$textColor-gray-900: #111010
$textColor-purple-base: #7569FF
$textColor-purple-light: #EEECFE
$textColor-purple-medium: #E3E0FD
$textColor-purple-dark: #8B87B0
$textColor-pink: #D869FF
$textColor-cream: #FFFCEF
$textColor-success: #00C11A
$textColor-warning: #FBBF24
$textColor-error: #EB001B

$textDecorationColor-inherit: inherit
$textDecorationColor-current: currentColor
$textDecorationColor-transparent: transparent
$textDecorationColor-white: #FFFFFF
$textDecorationColor-black: #000000
$textDecorationColor-gray-100: #F3F3F3
$textDecorationColor-gray-200: #DADADA
$textDecorationColor-gray-500: #626066
$textDecorationColor-gray-900: #111010
$textDecorationColor-purple-base: #7569FF
$textDecorationColor-purple-light: #EEECFE
$textDecorationColor-purple-medium: #E3E0FD
$textDecorationColor-purple-dark: #8B87B0
$textDecorationColor-pink: #D869FF
$textDecorationColor-cream: #FFFCEF
$textDecorationColor-success: #00C11A
$textDecorationColor-warning: #FBBF24
$textDecorationColor-error: #EB001B

$textDecorationThickness-0: 0
$textDecorationThickness-auto: auto
$textDecorationThickness-from-font: from-font
$textDecorationThickness-1px: 1px
$textDecorationThickness-2px: 2px
$textDecorationThickness-3px: 3px
$textDecorationThickness-4px: 4px

$textUnderlineOffset-0: 0
$textUnderlineOffset-auto: auto
$textUnderlineOffset-1px: 1px
$textUnderlineOffset-2px: 2px
$textUnderlineOffset-3px: 3px
$textUnderlineOffset-4px: 4px

$textIndent-0: 0
$textIndent-1: 0.25rem
$textIndent-2: 0.5rem
$textIndent-3: 0.75rem
$textIndent-4: 1rem
$textIndent-5: 1.25rem
$textIndent-6: 1.5rem
$textIndent-7: 1.75rem
$textIndent-8: 2rem
$textIndent-9: 2.25rem
$textIndent-10: 2.5rem
$textIndent-11: 2.75rem
$textIndent-12: 3rem
$textIndent-13: 3.25rem
$textIndent-14: 3.5rem
$textIndent-15: 3.75rem
$textIndent-16: 4rem
$textIndent-17: 4.25rem
$textIndent-18: 4.5rem
$textIndent-19: 4.75rem
$textIndent-20: 5rem
$textIndent-24: 6rem
$textIndent-28: 7rem
$textIndent-32: 8rem
$textIndent-36: 9rem
$textIndent-40: 10rem
$textIndent-44: 11rem
$textIndent-48: 12rem
$textIndent-52: 13rem
$textIndent-56: 14rem
$textIndent-60: 15rem
$textIndent-64: 16rem
$textIndent-72: 18rem
$textIndent-76: 19rem
$textIndent-80: 20rem
$textIndent-0\.5: 0.125rem
$textIndent-1\.5: 0.375rem
$textIndent-2\.5: 0.625rem
$textIndent-3\.5: 0.875rem
$textIndent-4\.5: 1.125rem
$textIndent-5\.5: 1.375rem
$textIndent-6\.5: 1.625rem
$textIndent-7\.5: 1.875rem

$textOpacity-0: 0
$textOpacity-5: 5%
$textOpacity-10: 10%
$textOpacity-15: 15%
$textOpacity-20: 20%
$textOpacity-25: 25%
$textOpacity-30: 30%
$textOpacity-35: 35%
$textOpacity-40: 40%
$textOpacity-45: 45%
$textOpacity-50: 50%
$textOpacity-55: 55%
$textOpacity-60: 60%
$textOpacity-65: 65%
$textOpacity-70: 70%
$textOpacity-75: 75%
$textOpacity-80: 80%
$textOpacity-85: 85%
$textOpacity-90: 90%
$textOpacity-95: 95%
$textOpacity-100: 100%

$transformOrigin-center: center
$transformOrigin-top: top
$transformOrigin-bottom: bottom
$transformOrigin-left: left
$transformOrigin-right: right
$transformOrigin-top-left: top left
$transformOrigin-top-right: top right
$transformOrigin-bottom-left: bottom left
$transformOrigin-bottom-right: bottom right

$transitionDelay-50: 50ms
$transitionDelay-100: 100ms
$transitionDelay-150: 150ms
$transitionDelay-200: 200ms
$transitionDelay-250: 250ms
$transitionDelay-500: 500ms
$transitionDelay-750: 750ms
$transitionDelay-1000: 1000ms

$transitionDuration-50: 50ms
$transitionDuration-100: 100ms
$transitionDuration-150: 150ms
$transitionDuration-200: 200ms
$transitionDuration-250: 250ms
$transitionDuration-500: 500ms
$transitionDuration-750: 750ms
$transitionDuration-1000: 1000ms
$transitionDuration-default: 250ms

$transitionProperty-none: none
$transitionProperty-all: all
$transitionProperty-colors: (color, background-color, border-color, outline-color, text-decoration-color)
$transitionProperty-shadow: box-shadow
$transitionProperty-opacity: opacity
$transitionProperty-transform: transform
$transitionProperty-svg: (fill, stroke)

$transitionTimingFunction-linear: linear
$transitionTimingFunction-in: (cubic-bezier(0.4, 0, 1, 1))
$transitionTimingFunction-out: (cubic-bezier(0, 0, 0.2, 1))
$transitionTimingFunction-in-out: (cubic-bezier(0.4, 0, 0.2, 1))
$transitionTimingFunction-default: linear

$translate-0: 0
$translate-1: 0.25rem
$translate-2: 0.5rem
$translate-3: 0.75rem
$translate-4: 1rem
$translate-5: 1.25rem
$translate-6: 1.5rem
$translate-7: 1.75rem
$translate-8: 2rem
$translate-9: 2.25rem
$translate-10: 2.5rem
$translate-11: 2.75rem
$translate-12: 3rem
$translate-13: 3.25rem
$translate-14: 3.5rem
$translate-15: 3.75rem
$translate-16: 4rem
$translate-17: 4.25rem
$translate-18: 4.5rem
$translate-19: 4.75rem
$translate-20: 5rem
$translate-24: 6rem
$translate-28: 7rem
$translate-32: 8rem
$translate-36: 9rem
$translate-40: 10rem
$translate-44: 11rem
$translate-48: 12rem
$translate-52: 13rem
$translate-56: 14rem
$translate-60: 15rem
$translate-64: 16rem
$translate-72: 18rem
$translate-76: 19rem
$translate-80: 20rem
$translate-1px: 1px
$translate-2px: 2px
$translate-3px: 3px
$translate-4px: 4px
$translate-0\.5: 0.125rem
$translate-1\.5: 0.375rem
$translate-2\.5: 0.625rem
$translate-3\.5: 0.875rem
$translate-4\.5: 1.125rem
$translate-5\.5: 1.375rem
$translate-6\.5: 1.625rem
$translate-7\.5: 1.875rem
$translate-5: 5%
$translate-10: 10%
$translate-15: 15%
$translate-20: 20%
$translate-25: 25%
$translate-30: 30%
$translate-35: 35%
$translate-40: 40%
$translate-45: 45%
$translate-50: 50%
$translate-55: 55%
$translate-60: 60%
$translate-65: 65%
$translate-70: 70%
$translate-75: 75%
$translate-80: 80%
$translate-85: 85%
$translate-90: 90%
$translate-95: 95%
$translate-100: 100%

$width-0: 0
$width-1: 0.25rem
$width-2: 0.5rem
$width-3: 0.75rem
$width-4: 1rem
$width-5: 1.25rem
$width-6: 1.5rem
$width-7: 1.75rem
$width-8: 2rem
$width-9: 2.25rem
$width-10: 2.5rem
$width-11: 2.75rem
$width-12: 3rem
$width-13: 3.25rem
$width-14: 3.5rem
$width-15: 3.75rem
$width-16: 4rem
$width-17: 4.25rem
$width-18: 4.5rem
$width-19: 4.75rem
$width-20: 5rem
$width-24: 6rem
$width-28: 7rem
$width-32: 8rem
$width-36: 9rem
$width-40: 10rem
$width-44: 11rem
$width-48: 12rem
$width-52: 13rem
$width-56: 14rem
$width-60: 15rem
$width-64: 16rem
$width-72: 18rem
$width-76: 19rem
$width-80: 20rem
$width-auto: auto
$width-min: min-content
$width-max: max-content
$width-fit: fit-content
$width-0\.5: 0.125rem
$width-1\.5: 0.375rem
$width-2\.5: 0.625rem
$width-3\.5: 0.875rem
$width-4\.5: 1.125rem
$width-5\.5: 1.375rem
$width-6\.5: 1.625rem
$width-7\.5: 1.875rem
$width-5: 5%
$width-10: 10%
$width-15: 15%
$width-20: 20%
$width-25: 25%
$width-30: 30%
$width-35: 35%
$width-40: 40%
$width-45: 45%
$width-50: 50%
$width-55: 55%
$width-60: 60%
$width-65: 65%
$width-70: 70%
$width-75: 75%
$width-80: 80%
$width-85: 85%
$width-90: 90%
$width-95: 95%
$width-100: 100%
$width-25vw: 25vw
$width-25vh: 25vh
$width-50vw: 50vw
$width-50vh: 50vh
$width-75vw: 75vw
$width-75vh: 75vh
$width-100vw: 100vw
$width-100vh: 100vh

$willChange-auto: auto
$willChange-contents: contents
$willChange-transform: transform
$willChange-scroll: scroll-position

$zIndex-1: 1
$zIndex-2: 2
$zIndex-3: 3
$zIndex-4: 4
$zIndex-5: 5
$zIndex-6: 6
$zIndex-7: 7
$zIndex-8: 8
$zIndex-9: 9
$zIndex-10: 10
$zIndex-11: 11
$zIndex-12: 12
$zIndex-auto: auto
$zIndex--1: -1

$borderSpacing-0: 0px
$borderSpacing-1: 0.25rem
$borderSpacing-2: 0.5rem
$borderSpacing-3: 0.75rem
$borderSpacing-4: 1rem
$borderSpacing-5: 1.25rem
$borderSpacing-6: 1.5rem
$borderSpacing-7: 1.75rem
$borderSpacing-8: 2rem
$borderSpacing-9: 2.25rem
$borderSpacing-10: 2.5rem
$borderSpacing-11: 2.75rem
$borderSpacing-12: 3rem
$borderSpacing-14: 3.5rem
$borderSpacing-16: 4rem
$borderSpacing-20: 5rem
$borderSpacing-24: 6rem
$borderSpacing-28: 7rem
$borderSpacing-32: 8rem
$borderSpacing-36: 9rem
$borderSpacing-40: 10rem
$borderSpacing-44: 11rem
$borderSpacing-48: 12rem
$borderSpacing-52: 13rem
$borderSpacing-56: 14rem
$borderSpacing-60: 15rem
$borderSpacing-64: 16rem
$borderSpacing-72: 18rem
$borderSpacing-80: 20rem
$borderSpacing-96: 24rem
$borderSpacing-px: 1px
$borderSpacing-0\.5: 0.125rem
$borderSpacing-1\.5: 0.375rem
$borderSpacing-2\.5: 0.625rem
$borderSpacing-3\.5: 0.875rem

$listStyleImage-none: none

$lineClamp-1: 1
$lineClamp-2: 2
$lineClamp-3: 3
$lineClamp-4: 4
$lineClamp-5: 5
$lineClamp-6: 6

$size-0: 0px
$size-1: 0.25rem
$size-2: 0.5rem
$size-3: 0.75rem
$size-4: 1rem
$size-5: 1.25rem
$size-6: 1.5rem
$size-7: 1.75rem
$size-8: 2rem
$size-9: 2.25rem
$size-10: 2.5rem
$size-11: 2.75rem
$size-12: 3rem
$size-14: 3.5rem
$size-16: 4rem
$size-20: 5rem
$size-24: 6rem
$size-28: 7rem
$size-32: 8rem
$size-36: 9rem
$size-40: 10rem
$size-44: 11rem
$size-48: 12rem
$size-52: 13rem
$size-56: 14rem
$size-60: 15rem
$size-64: 16rem
$size-72: 18rem
$size-80: 20rem
$size-96: 24rem
$size-auto: auto
$size-px: 1px
$size-0\.5: 0.125rem
$size-1\.5: 0.375rem
$size-2\.5: 0.625rem
$size-3\.5: 0.875rem
$size-1\/2: 50%
$size-1\/3: 33.333333%
$size-2\/3: 66.666667%
$size-1\/4: 25%
$size-2\/4: 50%
$size-3\/4: 75%
$size-1\/5: 20%
$size-2\/5: 40%
$size-3\/5: 60%
$size-4\/5: 80%
$size-1\/6: 16.666667%
$size-2\/6: 33.333333%
$size-3\/6: 50%
$size-4\/6: 66.666667%
$size-5\/6: 83.333333%
$size-1\/12: 8.333333%
$size-2\/12: 16.666667%
$size-3\/12: 25%
$size-4\/12: 33.333333%
$size-5\/12: 41.666667%
$size-6\/12: 50%
$size-7\/12: 58.333333%
$size-8\/12: 66.666667%
$size-9\/12: 75%
$size-10\/12: 83.333333%
$size-11\/12: 91.666667%
$size-full: 100%
$size-min: min-content
$size-max: max-content
$size-fit: fit-content
