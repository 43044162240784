// @import '~antd/es/input-number/style/index.css'

.ant-input-number
	$input: &

	width: 100%
	font-size: rem(17)
	font-weight: 400
	line-height: 1.25
	color: $textColor-gray-900
	background: $backgroundColor-gray-100
	border: 1px solid transparent
	border-radius: rem(8)
	transition: color 250ms, background 250ms, border 250ms
	cursor: text

	&-sm
		font-size: rem(15)

	&-lg
		font-size: rem(19)

	&-input
		width: 100%
		background: transparent
		padding: rem(16) rem(48) rem(16) rem(16)
		outline: none !important

		&::placeholder
			color: $textColor-gray-500

	&-handler-wrap
		display: none

	&:hover, &:focus
		border-color: $borderColor-purple-base

	&.error
		border-color: $borderColor-error
