// @import '~antd/es/table/style/index.css'

.ant-table
	$table: &

	table
		border-collapse: separate
		border-spacing: 0 rem(8)

	thead
		th
			text-align: left
			font-size: rem(13)
			font-weight: 500
			white-space: nowrap
			color: $textColor-purple-dark
			padding: 0 rem(20)

			@media (min-width: 992px)
				&:first-child
					padding-left: rem(32)

				&:last-child
					padding-right: rem(32)

	tbody
		tr
			position: relative
			background: $backgroundColor-white
			border-radius: rem(16)
			transition: color 250ms, background 250ms, box-shadow 250ms
			cursor: pointer

			&:not(.ant-table-placeholder):hover
				box-shadow: inset 0 0 0 2px $borderColor-purple-base

		td
			font-size: rem(15)
			padding: rem(20)

			&:first-child
				border-radius: rem(16) 0 0 rem(16)

				@media (min-width: 992px)
					padding-left: rem(32)

			&:last-child
				border-radius: 0 rem(16) rem(16) 0

				@media (min-width: 992px)
					padding-right: rem(32)

	&.ant-table-empty
		#{$table}-tbody
			#{$table}-cell
				padding: 0 !important
				border-radius: rem(16)
				box-shadow: inset 0 0 0 2px $borderColor-white

		#{$table}-container
			&::before, &::after
				content: none

	&.ant-table-scroll-horizontal
		#{$table}-container
			position: relative

			&::before, &::after
				// content: ''
				position: absolute
				z-index: 1
				top: rem(8 + 35) // 35px = thead height
				bottom: rem(8)
				width: rem(64)
				visibility: hidden
				opacity: 0
				transition: visibility 250ms ease-out, opacity 250ms ease-out
				pointer-events: none

				&:before
					left: 0
					background: linear-gradient(90deg, rgba($backgroundColor-white, 100%), rgba($backgroundColor-white, 0%))
					border-radius: rem(16) 0 0 rem(16)

				&:after
					right: 0
					background: linear-gradient(270deg, rgba($backgroundColor-white, 100%), rgba($backgroundColor-white, 0%))
					border-radius: 0 rem(16) rem(16) 0

			&.ant-table-ping-left
				#{$table}-container
					&::before
						visibility: visible
						opacity: 1

			&.ant-table-ping-right
				#{$table}-container
					&::after
						visibility: visible
						opacity: 1

/* Ellipsis */

.ant-table-cell-ellipsis
	overflow: hidden
	white-space: nowrap
	text-overflow: ellipsis
	word-break: keep-all

	.ant-table-column-title
		overflow: hidden
		text-overflow: ellipsis
		word-break: keep-all

	&.ant-table-cell-fix-left-last,
	&.ant-table-cell-fix-right-first
		overflow: visible

		.ant-table-cell-content
			display: block
			overflow: hidden
			text-overflow: ellipsis
