.ant-pagination
	$pagination: &
	display: flex
	justify-content: center
	align-items: center
	flex-wrap: wrap
	gap: rem(32)
	margin: rem(40) 0 rem(32) 0

	@media (max-width: 991px)
		gap: rem(16)

	@media (max-width: 767px)
		gap: rem(8)

	&-prev, &-next, &-item, &-jump-prev, &-jump-next
		width: rem(32)
		height: rem(32)
		display: inline-flex
		justify-content: center
		align-items: center
		flex-shrink: 0
		border-radius: 50%
		overflow: hidden
		user-select: none
		cursor: pointer

		&:hover
			color: $textColor-gray-900
			background: $backgroundColor-purple-medium

	&-prev, &-next
		font-size: rem(18)
		color: $textColor-gray-900

		> button
			display: inline-flex
			justify-content: center
			align-items: center
			pointer-events: none

	&-item
		font-size: rem(12)
		color: $textColor-gray-500

		&-active
			color: $textColor-white
			background: $backgroundColor-purple-base
			pointer-events: none

	&-jump-prev, &-jump-next
		font-size: rem(12)
		color: $textColor-gray-500

		#{$pagination}-item-link-icon
			display: none
