.ant-drawer
	$drawer: &

	&.create-withdrawal-drawer
		#{$drawer}-mask
			background: transparent

		#{$drawer}-content
			position: relative
			display: flex
			justify-content: center
			background: $backgroundColor-white

		#{$drawer}-wrapper-body
			width: 75%
			max-width: rem(800)
			text-align: center
			margin-top: rem(96)

		#{$drawer}-close
			position: absolute
			top: rem(40)
			right: 10%
			font-size: rem(18)

			@media (max-width: 1199px)
				top: rem(40)
				right: 5%

			@media (max-width: 991px)
				top: rem(28)
				right: rem(16)

		#{$drawer}-footer
			display: none
