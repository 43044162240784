.ant-modal
	max-width: 100%
	max-height: 100%
	pointer-events: auto

	&-mask
		position: fixed
		inset: 0
		z-index: 1000
		background: rgba($backgroundColor-black, 75%)

	&-wrap
		position: fixed
		inset: 0
		z-index: 1000
		display: flex
		justify-content: center
		margin: rem(32)

		&:not(.ant-modal-centered)
			align-items: start

		&.ant-modal-centered
			align-items: center
