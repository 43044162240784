// @import '~antd/es/input/style/index.css'

.ant-input
	$input: &

	width: 100%
	font-size: rem(17)
	font-weight: 400
	line-height: 1.25
	color: $textColor-gray-900
	background: $backgroundColor-gray-100
	border: 1px solid transparent
	border-radius: rem(8)
	transition: color 250ms, background 250ms, border 250ms, padding 250ms
	cursor: text

	&-sm
		font-size: rem(15)

	&-lg
		font-size: rem(19)

	@at-root input#{&}
		padding: rem(16) rem(48) rem(16) rem(16)
		outline: none !important

		&::placeholder
			color: $textColor-gray-500

		&[readonly]
			background: transparent
			padding: rem(16) 0
			border-radius: 0
			border-bottom-color: $borderColor-gray-200
			cursor: default

		&:not([readonly])
			&:hover, &:focus
				border-color: $borderColor-purple-base

			&.error
				border-color: $borderColor-error

.form-field-message
	&.error
		color: $borderColor-error
