// @import ~antd/es/drawer/style/index.css

.ant-drawer
	$drawer: &
	$transition-open: 500ms cubic-bezier(0.25, 1, 0.25, 1)
	$transition-close: 500ms cubic-bezier(0.25, 1, 0.25, 1)

	position: fixed
	z-index: 1000
	transition: width $transition-open, height $transition-open

	&-mask
		position: absolute
		inset: 0
		opacity: 0
		transition: opacity $transition-open
		pointer-events: none

	&-content-wrapper
		position: absolute
		width: 100%
		height: 100%
		transition: transform $transition-close

	&-content
		position: relative
		z-index: 1
		width: 100%
		height: 100%
		overflow: auto

	/* Placements */

	&-top, &-bottom
		left: 0
		right: 0
		height: 0

	&-left, &-right
		top: 0
		bottom: 0
		width: 0

	&-top
		top: 0

		#{$drawer}-content-wrapper
			top: 0

	&-bottom
		bottom: 0

		#{$drawer}-content-wrapper
			bottom: 0

	&-left
		left: 0

		#{$drawer}-content-wrapper
			left: 0

	&-right
		right: 0

		#{$drawer}-content-wrapper
			right: 0

	/* Opened state */

	&-open
		&#{$drawer}-top, &#{$drawer}-bottom
			height: 100%

		&#{$drawer}-left, &#{$drawer}-right
			width: 100%

		#{$drawer}-mask
			opacity: 1
			pointer-events: auto
