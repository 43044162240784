// @import '~antd/es/select/style/index.css'

.ant-select
	$select: &

	position: relative
	width: 100%
	font-size: rem(17)
	font-weight: 400
	line-height: 1.25
	color: $textColor-gray-900
	background: $backgroundColor-gray-100
	border: 1px solid transparent
	border-radius: rem(8)
	transition: color 250ms, background 250ms, border 250ms

	&-sm
		font-size: rem(15)

	&-lg
		font-size: rem(19)

	&[readonly]
		background: transparent
		border-radius: 0
		border-bottom-color: $borderColor-gray-200
		cursor: default

		#{$select}-selector
			padding: rem(16) 0
			pointer-events: none

		#{$select}-arrow
			opacity: 0
			visibility: hidden

	&:not([readonly])
		&:hover, &:focus, #{$select}-open
			border-color: $borderColor-purple-base

		&.error
			border-color: $borderColor-error

	&-selector
		position: relative
		width: 100%
		background: transparent
		padding: rem(16) rem(48) rem(16) rem(16)
		transition: padding 250ms
		outline: none !important
		cursor: pointer

	&-selection
		&-placeholder, &-item
			display: flex
			white-space: nowrap
			overflow: hidden

		&-search
			position: absolute
			inset: 0

		&-placeholder
			color: $textColor-gray-500

		&-item
			color: $textColor-gray-900

	&-arrow
		position: absolute
		z-index: 1
		top: 50%
		right: rem(16)
		transform: translateY(-50%)
		display: inline-flex
		width: rem(18)
		height: rem(18)
		font-size: rem(18)
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M0.26,5.073l7.111,7.111c0.348,0.347 0.91,0.347 1.258,-0l7.111,-7.111c0.347,-0.347 0.347,-0.91 -0,-1.257c-0.347,-0.347 -0.91,-0.347 -1.257,0l-6.483,6.482l-6.483,-6.482c-0.347,-0.347 -0.91,-0.347 -1.257,0c-0.347,0.347 -0.347,0.91 0,1.257Z' fill='%23626066' /%3E%3C/svg%3E%0A")
		background-position: center
		background-size: cover
		transition: opacity 250ms, visibility 250ms
		pointer-events: none

		.anticon
			display: none

	&-dropdown
		position: absolute
		top: -9999px
		left: -9999px
		z-index: 1050
		padding: rem(4) 0
		cursor: pointer

		&-hidden
			display: none

		&-placement-topLeft, &-placement-topRight
			&.ant-slide-up-appear-active, &.ant-slide-up-enter-active
				animation-name: antSlideDownIn
			&.ant-slide-up-leave-active
				animation-name: antSlideDownOut

		&-placement-bottomLeft, &-placement-bottomRight
			&.ant-slide-up-appear-active, &.ant-slide-up-enter-active
				animation-name: antSlideUpIn
			&.ant-slide-up-leave-active
				animation-name: antSlideUpOut

		> div
			background: $backgroundColor-white
			padding: rem(8) 0
			border-radius: rem(8)
			box-shadow: $boxShadow-md

	&-item-option
		font-size: rem(15)
		line-height: 1.25
		color: $textColor-gray-500
		background: $backgroundColor-white
		padding: rem(16)
		border-left: 2px solid transparent
		transition: color 250ms, background 250ms, border-color 250ms

		&-active
			color: $textColor-gray-900
			background: $backgroundColor-purple-light
			border-color: $backgroundColor-purple-base

	input
		width: 100%
		height: 100%
		background: transparent
		padding: 16px
		&:focus-visible
			outline: none !important
.ant-select-item-option-content
	display: flex !important
	flex-direction: row !important

