body
	font-family: $fontFamily-base
	font-size: 1rem

a
	color: inherit
	text-decoration: none
	transition: color 250ms

	&:hover
		color: inherit
		text-decoration: none

p a
	color: inherit
	text-decoration: underline
	transition: color 250ms

	&:hover
		color: $textColor-purple-base
		text-decoration: underline

.text-gradient
	background: linear-gradient(175deg, $backgroundColor-pink -10%, $backgroundColor-purple-base 110%)
	background-size: 1px 100%
	-webkit-background-clip: text
	-webkit-text-fill-color: transparent

	&:hover
		background-size: 1px 200%
