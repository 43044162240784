.document-format-text
	font-size: rem(14)

	h2
		font-size: rem(18)
		font-weight: 700
		margin-bottom: 1rem

	h3
		font-weight: 700
		margin-bottom: 1rem

	p, ol, ul
		margin-bottom: 1rem

	p + ol, p + ul
		margin-top: -1rem

	address
		display: block
		margin-top: 1rem
		margin-bottom: 1rem

	> ol
		counter-reset: item
		padding-left: 2rem

		> li
			counter-increment: item
			position: relative
			margin-bottom: 3rem

			&::before
				content: counters(item, '.') '. '
				font-weight: 700
				position: absolute
				top: 0
				left: -2rem

			> ol
				counter-reset: item

				> li
					counter-increment: item
					position: relative

					&::before
						content: counters(item, '.') '. '
						position: absolute
						top: 0
						left: -2rem

					> ol, ul
						counter-reset: list
						margin-left: 2.5rem

						> li
							counter-increment: list
							position: relative

							&::before
								position: absolute
								top: 0
								left: -2.5rem

						&.dash-list > li::before
							content: '-'

						&.letter-list > li::before
							content: counter(list, lower-alpha)')'

						&.numeral-list > li::before
							content: '('counter(list, lower-roman)')'
