// @import ~antd/es/date-picker/style/index.css

.ant-picker
	$picker: &

	width: 100%
	font-size: rem(17)
	font-weight: 400
	line-height: 1.25
	color: $textColor-gray-900
	background: $backgroundColor-gray-100
	border: 1px solid transparent
	border-radius: rem(8)
	transition: color 250ms, background 250ms, border 250ms
	cursor: pointer

	&-small
		font-size: rem(15)

	&-large
		font-size: rem(19)

	&:hover, &:focus, &.focused
		border-color: $borderColor-purple-base

	&.error
		border-color: $borderColor-error

	&-input > input
		width: 100%
		background: transparent
		padding: rem(16) rem(48) rem(16) rem(16)
		outline: none !important
		pointer-events: none

		&::placeholder
			color: $textColor-gray-500

		&:disabled
			color: rgba($textColor-gray-900, 30%)
			cursor: not-allowed

			&::placeholder
				color: rgba($textColor-gray-500, 30%)

	&-suffix
		display: none

	&-dropdown
		position: absolute
		top: -9999px
		left: -9999px
		z-index: 1050
		padding: rem(4) 0
		cursor: pointer

		&-hidden
			display: none

		&-placement-topLeft, &-placement-topRight
			&.ant-slide-up-appear-active, &.ant-slide-up-enter-active
				animation-name: antSlideDownIn
			&.ant-slide-up-leave-active
				animation-name: antSlideDownOut

		&-placement-bottomLeft, &-placement-bottomRight
			&.ant-slide-up-appear-active, &.ant-slide-up-enter-active
				animation-name: antSlideUpIn
			&.ant-slide-up-leave-active
				animation-name: antSlideUpOut

		> div
			background: $backgroundColor-white
			padding: rem(24)
			border-radius: rem(8)
			box-shadow: $boxShadow-md

	&-header
		display: flex
		justify-content: center
		align-items: center
		gap: rem(8)
		margin-bottom: rem(24)

		&-view
			display: inline-flex
			justify-content: center
			align-items: center
			gap: rem(8)
			font-size: rem(20)
			font-weight: 700
			margin: 0 auto

			#{$picker}-month-btn,
			#{$picker}-year-btn
				transition: color 250ms

				&:hover
					color: $textColor-purple-base

		&-super-prev-btn,
		&-prev-btn,
		&-super-next-btn,
		&-next-btn
			width: rem(32)
			height: rem(32)
			display: inline-flex
			justify-content: center
			align-items: center
			font-size: rem(12)
			background: $backgroundColor-white
			border: 1px solid $borderColor-gray-200
			border-radius: rem(8)
			transition: border 250ms

			&:hover
				border-color: $borderColor-purple-base

		&-super-prev-btn,
		&-super-next-btn
			display: none

		#{$picker}-super-prev-icon,
		#{$picker}-prev-icon,
		#{$picker}-next-icon,
		#{$picker}-super-next-icon
			width: 1em
			height: 1em
			background-size: cover
			background-position: center
			transform-origin: center

		#{$picker}-super-prev-icon
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M0.26,7.74l7.111,7.111c0.348,0.346 0.91,0.346 1.258,-0l7.111,-7.111c0.347,-0.348 0.347,-0.911 0,-1.257c-0.347,-0.347 -0.911,-0.347 -1.257,-0l-6.483,6.482l-6.483,-6.482c-0.346,-0.347 -0.91,-0.347 -1.257,-0c-0.347,0.346 -0.347,0.91 -0,1.257Z' /%3E%3Cpath d='M0.26,2.407l7.111,7.11c0.348,0.347 0.91,0.347 1.258,0l7.111,-7.11c0.347,-0.347 0.347,-0.911 0,-1.258c-0.347,-0.346 -0.911,-0.346 -1.257,0l-6.483,6.483l-6.483,-6.483c-0.346,-0.346 -0.91,-0.346 -1.257,0c-0.347,0.347 -0.347,0.911 -0,1.258Z' /%3E%3C/svg%3E")
			transform: rotate(+90deg)

		#{$picker}-prev-icon
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M0.26,5.073l7.111,7.111c0.348,0.347 0.91,0.347 1.258,0l7.111,-7.111c0.347,-0.347 0.347,-0.91 -0,-1.257c-0.347,-0.347 -0.91,-0.347 -1.257,-0l-6.483,6.482c-0,0 -6.483,-6.482 -6.483,-6.482c-0.347,-0.347 -0.91,-0.347 -1.257,-0c-0.347,0.347 -0.347,0.91 0,1.257Z' /%3E%3C/svg%3E")
			transform: rotate(+90deg)

		#{$picker}-next-icon
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M0.26,5.073l7.111,7.111c0.348,0.347 0.91,0.347 1.258,0l7.111,-7.111c0.347,-0.347 0.347,-0.91 -0,-1.257c-0.347,-0.347 -0.91,-0.347 -1.257,-0l-6.483,6.482c-0,0 -6.483,-6.482 -6.483,-6.482c-0.347,-0.347 -0.91,-0.347 -1.257,-0c-0.347,0.347 -0.347,0.91 0,1.257Z' /%3E%3C/svg%3E")
			transform: rotate(-90deg)

		#{$picker}-super-next-icon
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M0.26,7.74l7.111,7.111c0.348,0.346 0.91,0.346 1.258,-0l7.111,-7.111c0.347,-0.348 0.347,-0.911 0,-1.257c-0.347,-0.347 -0.911,-0.347 -1.257,-0l-6.483,6.482l-6.483,-6.482c-0.346,-0.347 -0.91,-0.347 -1.257,-0c-0.347,0.346 -0.347,0.91 -0,1.257Z' /%3E%3Cpath d='M0.26,2.407l7.111,7.11c0.348,0.347 0.91,0.347 1.258,0l7.111,-7.11c0.347,-0.347 0.347,-0.911 0,-1.258c-0.347,-0.346 -0.911,-0.346 -1.257,0l-6.483,6.483l-6.483,-6.483c-0.346,-0.346 -0.91,-0.346 -1.257,0c-0.347,0.347 -0.347,0.911 -0,1.258Z' /%3E%3C/svg%3E")
			transform: rotate(-90deg)

	&-body
		table
			width: 100%

			thead
				&::after
					content: ''
					display: inline-block
					height: rem(8)

				th
					font-size: rem(12)
					font-weight: 400
					color: $textColor-gray-500
					user-select: none

			tbody
				#{$picker}-cell
					padding: rem(4)
					cursor: pointer

					&-inner
						height: rem(36)
						display: inline-flex
						justify-content: center
						align-items: center
						color: $textColor-gray-900
						background: $backgroundColor-white
						border: solid 1px transparent
						border-radius: rem(8)
						transition: color 100ms, background 100ms, border 100ms, box-shadow 100ms

					&:not(&-in-view)
						#{$picker}-cell-inner
							color: $textColor-gray-200

					&-today
						#{$picker}-cell-inner
							color: $textColor-purple-base
							background: $backgroundColor-white
							border-color: $borderColor-purple-base

					&:not(&-selected)
						&:hover
							#{$picker}-cell-inner
								color: $textColor-gray-900
								background: $backgroundColor-white
								border-color: $borderColor-gray-900
								transition-duration: 0s

						&:active
							#{$picker}-cell-inner
								color: $textColor-white
								background: $backgroundColor-purple-base
								border-color: transparent

					&-selected
						#{$picker}-cell-inner
							color: $textColor-white
							background: $backgroundColor-purple-base
							border-color: transparent
							box-shadow: 0 0 0 4px rgba($backgroundColor-purple-base, 15%)

	&-year-panel
		#{$picker}-cell-inner
			width: rem(60)

	&-month-panel
		#{$picker}-cell-inner
			width: rem(52)

	&-date-panel
		#{$picker}-cell-inner
			width: rem(36)
