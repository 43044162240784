// @import ~antd/es/progress/style/index.css

.ant-progress
	$progress: &

	width: 100%
	background: darken($backgroundColor-purple-medium, 3.5%)
	border-radius: $borderRadius-sm
	overflow: hidden

	&-bg
		background: linear-gradient(180deg, $backgroundColor-pink -10%, $backgroundColor-purple-base 110%)
		border-radius: $borderRadius-sm
