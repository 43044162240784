/* Poppins */

@font-face
	font-family: 'Poppins'
	src: local('Poppins Thin'), url('../../assets/fonts/Poppins/Poppins-Thin.woff2') format('woff2')
	font-style: normal
	font-weight: 100
	font-display: swap

@font-face
	font-family: 'Poppins'
	src: local('Poppins ExtraLight'), url('../../assets/fonts/Poppins/Poppins-ExtraLight.woff2') format('woff2')
	font-style: normal
	font-weight: 200
	font-display: swap

@font-face
	font-family: 'Poppins'
	src: local('Poppins Light'), url('../../assets/fonts/Poppins/Poppins-Light.woff2') format('woff2')
	font-style: normal
	font-weight: 300
	font-display: swap

@font-face
	font-family: 'Poppins'
	src: local('Poppins Regular'), url('../../assets/fonts/Poppins/Poppins-Regular.woff2') format('woff2')
	font-style: normal
	font-weight: 400
	font-display: swap

@font-face
	font-family: 'Poppins'
	src: local('Poppins Medium'), url('../../assets/fonts/Poppins/Poppins-Medium.woff2') format('woff2')
	font-style: normal
	font-weight: 500
	font-display: swap

@font-face
	font-family: 'Poppins'
	src: local('Poppins SemiBold'), url('../../assets/fonts/Poppins/Poppins-SemiBold.woff2') format('woff2')
	font-style: normal
	font-weight: 600
	font-display: swap

@font-face
	font-family: 'Poppins'
	src: local('Poppins Bold'), url('../../assets/fonts/Poppins/Poppins-Bold.woff2') format('woff2')
	font-style: normal
	font-weight: 700
	font-display: swap

@font-face
	font-family: 'Poppins'
	src: local('Poppins ExtraBold'), url('../../assets/fonts/Poppins/Poppins-ExtraBold.woff2') format('woff2')
	font-style: normal
	font-weight: 800
	font-display: swap

@font-face
	font-family: 'Poppins'
	src: local('Poppins Black'), url('../../assets/fonts/Poppins/Poppins-Black.woff2') format('woff2')
	font-style: normal
	font-weight: 900
	font-display: swap
