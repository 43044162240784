// @import ~antd/es/button/style/index.css

.ant-btn
	position: relative
	display: inline-flex
	justify-content: center
	align-items: center
	gap: rem(8)
	transition: color 250ms, background 250ms, border 250ms, opacity 250ms

	/* Variants */

	&-text
		text-decoration: none

	&-link
		text-decoration: underline

	&-default, &-dashed, &-primary, &-ghost, &-dangerous
		font-size: rem(18)
		font-weight: 500
		line-height: 1.5
		padding: rem(16) rem(56)

		@media (max-width: 991px)
			font-size: rem(16)
			padding: rem(16) rem(40)

		&.ant-btn-sm
			font-size: rem(13)
			padding: rem(10) rem(24)

		&.ant-btn-lg
			font-size: rem(22)
			padding: rem(20) rem(64)

		&.ant-btn-block
			width: 100%

		&.ant-btn-round
			border-radius: 999px

		&.ant-btn-circle
			border-radius: 50%

	&-default
		color: $textColor-gray-900
		background: transparent
		border: solid 1px $textColor-gray-900

	&-dashed
		color: $textColor-gray-900
		background: transparent
		border: dashed 1px $textColor-gray-900

	&-primary
		&[disabled]
			color: rgba($textColor-gray-900, 35%)
			background: $backgroundColor-gray-100
			cursor: not-allowed

		&:not([disabled])
			color: $textColor-white
			background: linear-gradient(175deg, $backgroundColor-pink -10%, $backgroundColor-purple-base 110%)
			background-size: 1px 100%

			&:hover
				background-size: 1px 200%

	&-ghost
		&[disabled]
			color: rgba($textColor-gray-900, 35%)
			background: $backgroundColor-gray-100
			cursor: not-allowed

		&:not([disabled])
			color: $textColor-white
			background: linear-gradient(175deg, $backgroundColor-pink -10%, $backgroundColor-purple-base 110%)
			background-size: 1px 100%

			&:hover
				background-size: 1px 200%

			&::before
				content: ''
				position: absolute
				z-index: 1
				inset: 1px
				background: $backgroundColor-white
				border-radius: inherit

			> svg
				position: relative
				z-index: 2

			> span
				position: relative
				z-index: 2
				background: inherit
				background-size: inherit
				-webkit-background-clip: text
				-webkit-text-fill-color: transparent
				transition: background 250ms

			&:hover
				> span
					background-size: 1px 200%

	&-dangerous
		color: $textColor-white
		background: $backgroundColor-error
		border: none

	/* States */

	&-loading
		cursor: not-allowed
		pointer-events: none

		&-icon
			display: inline-flex
			transition: width 250ms, opacity 250ms

			.anticon > svg
				width: 1em
				height: 1em
