// @import '~antd/es/switch/style/index.css'

.ant-switch
	$switch: &

	position: relative
	width: rem(52)
	height: rem(32)
	background: $backgroundColor-gray-100
	border: solid 1px transparent
	border-radius: rem(28)

	&-handle
		position: absolute
		z-index: 1
		top: 2px
		bottom: 2px
		left: 2px
		width: rem(32 - 6)
		height: rem(32 - 6)
		background: $backgroundColor-white
		border-radius: 50%
		transition: background 250ms, left 250ms, transform 250ms

	&:active
		#{$switch}-handle
			transform: scaleX(1.25)

		&:not(.ant-switch-checked)
			#{$switch}-handle
				transform-origin: left

		&.ant-switch-checked
			#{$switch}-handle
				transform-origin: right

	&-checked
		#{$switch}-handle
			left: calc(100% - 2px - #{rem(32 - 6)})
			background: $backgroundColor-purple-base

	&.error
		border-color: $borderColor-error

		#{$switch}-handle
			background: rgba($backgroundColor-error, 30%)
