.withdrawals-history-table
	.ant-table
		table
			border-spacing: 0 1px

		.ant-table-row
			position: relative
			border-radius: 0

			&::after
				content: ''
				position: absolute
				bottom: 0
				left: 0
				right: 0
				height: 1px
				background: $backgroundColor-gray-200

			&:hover
				box-shadow: none

		.ant-table-cell
			border-radius: 0
			cursor: auto
			user-select: auto

			&:hover
				box-shadow: none
