html
	-webkit-font-smoothing: antialiased
	scroll-behavior: smooth

body
	color: $textColor-gray-900
	background: $backgroundColor-white

main
	height: 100%
	display: flex
	flex-direction: column
	flex-wrap: nowrap
