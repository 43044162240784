.form-field
	display: flex
	flex-direction: column
	gap: rem(4)

	&-label
		font-size: rem(14)
		font-weight: 500
		color: $textColor-gray-500

	&-item
		position: relative

		&-suffix
			position: absolute
			z-index: 1
			top: 50%
			right: rem(16)
			transform: translateY(-50%)
			display: inline-flex
			font-size: rem(20)
			pointer-events: none

			> button
				pointer-events: auto

	&-message
		font-size: rem(12)
		font-weight: 400
		color: $textColor-gray-500
