.phone_number
	border: 1px solid #F3F3F3
	&.error, &.error:hover, &.error:focus-within
		border-color: red
	input:focus-visible
		outline: none
	.ant-select-focused, .ant-select:hover
		border: 1px solid #F3F3F3 !important
	&:hover, &:focus-within
		border-color: #7569FF
	.ant-select
		cursor: pointer

