.employees-list-table
	.ant-table-cell

		.ant-input-number
			font-size: rem(15)
			background: transparent
			border-color: $borderColor-gray-200

			&:hover, &:focus
				border-color: $borderColor-gray-500

			&-input
				padding: rem(16) rem(8)
				text-align: right

				&:placeholder-shown
					text-align: center

		.ant-picker
			background: transparent
			border-color: $borderColor-gray-200

			&:hover, &:focus, &.ant-picker-focused
				border-color: $borderColor-gray-500

			&-input > input
				font-size: rem(15)
				padding: rem(16) rem(8)
				text-align: center
