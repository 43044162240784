.ant-notification
	$notification: &

	position: fixed
	z-index: 1010
	margin: 0 rem(24)

	&-notice
		width: rem(420)
		max-width: calc(100vw - #{rem(48)})
		position: relative
		background: $backgroundColor-white
		padding: rem(16) rem(16 + 12 + 16) rem(16) rem(16 + 32 + 16)
		border: solid 1px $borderColor-gray-100
		border-radius: rem(8)
		box-shadow: $boxShadow-md
		margin-bottom: rem(16)

		&-close
			position: absolute
			z-index: 1
			top: rem(16)
			right: rem(16)
			display: block
			width: rem(12)
			height: rem(12)
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M 15.803 13.922 C 15.929 14.047 16 14.218 16 14.396 C 16 14.573 15.929 14.744 15.803 14.869 L 14.869 15.803 C 14.744 15.929 14.573 16 14.396 16 C 14.218 16 14.047 15.929 13.922 15.803 L 8 9.881 L 2.078 15.803 C 1.953 15.929 1.782 16 1.604 16 C 1.427 16 1.256 15.929 1.131 15.803 L 0.197 14.869 C 0.071 14.744 0 14.573 0 14.396 C 0 14.218 0.071 14.047 0.197 13.922 L 6.119 8 L 0.197 2.078 C 0.071 1.953 0 1.782 0 1.604 C 0 1.427 0.071 1.256 0.197 1.131 L 1.131 0.197 C 1.256 0.071 1.427 0 1.604 0 C 1.782 0 1.953 0.071 2.078 0.197 L 8 6.119 L 13.922 0.197 C 14.047 0.071 14.218 0 14.396 0 C 14.573 0 14.744 0.071 14.869 0.197 L 15.803 1.131 C 15.929 1.256 16 1.427 16 1.604 C 16 1.782 15.929 1.953 15.803 2.078 L 9.881 8 L 15.803 13.922 Z' fill='%23626066' /%3E%3C/svg%3E")
			background-position: center
			background-size: cover

			&-x
				display: none

		&-icon
			position: absolute
			z-index: 1
			top: rem(16)
			left: rem(16)
			display: block
			width: rem(32)
			height: rem(32)
			font-size: rem(32)

		&-message
			font-size: rem(17)
			font-weight: 500
			color: $textColor-gray-900
			margin-bottom: rem(4)

		&-description
			font-size: rem(15)
			font-weight: 400
			color: $textColor-gray-900

	&-fade-leave, &-fade-enter, &-fade-appear
		animation-duration: 250ms
		animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1)
		animation-fill-mode: both
		animation-play-state: paused

	&-fade-leave-active
		animation-name: notificationFadeOut
		animation-play-state: running

	&-fade-enter-active, &-fade-appear-active
		animation-play-state: running

	&.ant-notification-top
		#{$notification}-fade-enter, #{$notification}-fade-appear
			animation-name: notificationFadeInTop

	&.ant-notification-bottom
		#{$notification}-fade-enter, #{$notification}-fade-appear
			animation-name: notificationFadeInBottom

	&.ant-notification-topLeft, &.ant-notification-bottomLeft
		#{$notification}-fade-enter, #{$notification}-fade-appear
			animation-name: notificationFadeInLeft

	&.ant-notification-topRight, &.ant-notification-bottomRight
		#{$notification}-fade-enter, #{$notification}-fade-appear
			animation-name: notificationFadeInRight

@keyframes notificationFadeOut
	0%
		opacity: 1
	100%
		opacity: 0

@keyframes notificationFadeInTop
	0%
		margin-top: -100%
		opacity: 0
	100%
		margin-top: 0
		opacity: 1

@keyframes notificationFadeInBottom
	0%
		margin-bottom: -100%
		opacity: 0
	100%
		margin-bottom: 0
		opacity: 1

@keyframes notificationFadeInLeft
	0%
		right: rem(420)
		opacity: 0
	100%
		right: 0
		opacity: 1

@keyframes notificationFadeInRight
	0%
		left: rem(420)
		opacity: 0
	100%
		left: 0
		opacity: 1
