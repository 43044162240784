.create-withdrawal-form
	.ant-input-number
		background: transparent
		border-color: transparent

		&-input
			text-align: center
			font-size: rem(40)
			font-weight: 600
			background: $backgroundColor-white
			border: solid 1px $borderColor-gray-900
			border-radius: rem(16)
			padding: rem(24) rem(32)

			@media (max-width: 991px)
				padding: rem(12) rem(32)
